<template>
  

  <div> 
    <vue-slider id="my-slider" @change="slider" :enable-cross="false" v-model="value"></vue-slider>
    
    <div class="b-card">
      <b-table hover :items="items"></b-table>
    </div>
  </div>
</template>
<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

function getDateString(date, format) {
  var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    getPaddedComp = function (comp) {
      return parseInt(comp) < 10 ? "0" + comp : comp;
    },
    formattedDate = format,
    o = {
      "y+": date.getFullYear(), // year
      "M+": months[date.getMonth()], //month
      "d+": getPaddedComp(date.getDate()), //day
      "h+": getPaddedComp(
        date.getHours() > 12 ? date.getHours() % 12 : date.getHours()
      ), //hour
      "H+": getPaddedComp(date.getHours()), //hour
      "m+": getPaddedComp(date.getMinutes()), //minute
      "s+": getPaddedComp(date.getSeconds()), //second
      "S+": getPaddedComp(date.getMilliseconds()), //millisecond,
      "b+": date.getHours() >= 12 ? "PM" : "AM",
    };

  for (var k in o) {
    if (new RegExp("(" + k + ")").test(format)) {
      formattedDate = formattedDate.replace(RegExp.$1, o[k]);
    }
  }
  return formattedDate;
}
function DiffSeconds(t1, t2) {
  var diff = Math.ceil((new Date(t2) - new Date(t1)) / 1000);
  return diff;
}
function mkmon(mon) {
  var res = "01";
  if (mon.match("Feb") != null) {
    res = "02";
  }
  if (mon.match("Mar")) {
    res = "03";
  }
  if (mon.match("Apr")) {
    res = "04";
  }
  if (mon.match("May")) {
    res = "05";
  }
  if (mon.match("Jun")) {
    res = "06";
  }
  if (mon.match("Jul")) {
    res = "07";
  }
  if (mon.match("Aug")) {
    res = "08";
  }
  if (mon.match("Sep")) {
    res = "09";
  }
  if (mon.match("Oct")) {
    res = "10";
  }
  if (mon.match("Nov")) {
    res = "11";
  }
  if (mon.match("Dec")) {
    res = "12";
  }
  return res;
}
function RTC(rtc) {
  var aaa = rtc.split(/[:, -]/);
  var day = aaa[0];
  var mon = aaa[1];
  var year = aaa[2];
  var hour = aaa[3];
  var min = aaa[4];
  var sec = aaa[5];
  var str =
    year + "-" + mkmon(mon) + "-" + day + " " + hour + ":" + min + ":" + sec;
  this.t = Date.parse(str);
  this.year = year;
  this.ValidYearSince = 2015;
}

const debounce = function debounce(fn, delay) {
  var timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    var args = arguments;
    var that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
};

export default {
  name: "ARangePicker",
  components: {
    VueSlider
  },
  props: {
    begin: String,
    end:String,
    begin_time:String,
    end_time:String
  },
  watch: {
    value: debounce(function() {
      this.update();
    }, 500),
  },
  mounted: function () {
    this.loading = true;
    this.buildRange();
    this.loading = false;
  },
  computed: {
    items() {
      return [
        {
          "start": this.start_time,
          "stop": this.stop_time
        }
      ]
    },
    start_time() {      
      if(this.begin_time == "" || this.end_time == "") {
        return "";
      }

      const begin_rtc = new RTC(this.begin_time);
      const end_rtc = new RTC(this.end_time);
      const diff = DiffSeconds(begin_rtc.t, end_rtc.t);
      
      let start_diff_sec = Math.round(diff * this.value[0] / 100);

      let d = new Date(begin_rtc.t);
      d.setSeconds(d.getSeconds() + start_diff_sec);

      return getDateString(d, "d-M-y HH:mm:ss");   
    },
    stop_time() {
      if(this.begin_time == "" || this.end_time == "") {
        return "";
      }


      const begin_rtc = new RTC(this.begin_time);
      const end_rtc = new RTC(this.end_time);
      const diff = DiffSeconds(begin_rtc.t, end_rtc.t);
      
      let stop_diff_sec = Math.round(diff * this.value[1] / 100);
      let d2 = new Date(begin_rtc.t);
      d2.setSeconds(d2.getSeconds() + stop_diff_sec);


      return getDateString(d2, "d-M-y HH:mm:ss");   


    }

  },
  methods: {
    update() {
      if(this.begin_time == "" || this.end_time == "") {
        return;
      }
      let begin_rtc = new RTC(this.begin_time);
      let end_rtc = new RTC(this.end_time);
      let diff = DiffSeconds(begin_rtc.t, end_rtc.t);
      
      let start_diff_sec = Math.round(diff * this.value[0] / 100);
      let end_diff_sec = Math.round(diff * this.value[1] /100);
      console.log(diff + " " + start_diff_sec + " " + end_diff_sec);
      let d = new Date(begin_rtc.t);
      d.setSeconds(d.getSeconds() + start_diff_sec);
      let d2 = new Date(begin_rtc.t);
      d2.setSeconds(d2.getSeconds() + end_diff_sec);
      //console.log(getDateString(d, "d-M-y HH:mm:ss"), 
      //  getDateString(d2, "d-M-y HH:mm:ss"));      
      this.$emit("sliding", {"start": getDateString(d, "d-M-y HH:mm:ss"), "stop":  getDateString(d2, "d-M-y HH:mm:ss")});
    },
    buildRange() {
    },
    slider() {        
      if(this.verbose)
        console.log("sliding", this.value)   ;            
    }    
  },
  data: function () {
    return {
      value: [0, 100],
      verbose: false
    };
  },
};
</script>

<style scoped>

.a-range-picker {
  width: 450px; /*300px; */
  margin: auto;
  text-align: center;
  position: relative;
  height: 6em;
  border: #ddd;
  border-width: 1rem;
  
}

.a-range-picker svg,
.a-range-picker input[type=range] {
  position: absolute;
  left: 0;
  bottom: 0;
}

input[type=number] {
  border: 1px solid #ddd;
  text-align: center;
  font-size: 1.6em;
  -moz-appearance: textfield;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=number]:invalid,
input[type=number]:out-of-range {
  border: 2px solid #ff6347;
}

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #2497e3;
}

input[type=range]:focus::-ms-fill-lower {
  background: #2497e3;
}

input[type=range]:focus::-ms-fill-upper {
  background: #2497e3;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: #2497e3;
  border-radius: 1px;
  box-shadow: none;
  border: 0;
}

input[type=range]::-webkit-slider-thumb {
  z-index: 2;
  position: relative;
  box-shadow: 0px 0px 0px #000;
  border: 1px solid #2497e3;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #a1d0ff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}
.input-table {
  position: absolute;
  z-index: 0;
}
@keyframes input-forwards {
  from {
    opacity: 0;
    top: 50px;
  }
  to {
    opacity: 1;
    top: 60px;
  }
}

@keyframes input-backwards {
  from {
    opacity: 1;
    top: 60px;
  }
  to {
    opacity: 0;
    top: 50px;
  }
}
</style>