var mylib = {
  checkBrowser(){
    let browser = "";
    let c = navigator.userAgent.search("Chrome");
    let f = navigator.userAgent.search("Firefox");
    let m8 = navigator.userAgent.search("MSIE 8.0");
    let m9 = navigator.userAgent.search("MSIE 9.0");
    if (c > -1) {
        browser = "Chrome";
    } else if (f > -1) {
        browser = "Firefox";
    } else if (m9 > -1) {
        browser ="MSIE 9.0";
    } else if (m8 > -1) {
        browser ="MSIE 8.0";
    }
    return browser;
  },
  
  wait(ms) {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  },
  pFileReader(file) {
    return new Promise((resolve, reject) => {
      let fr = new FileReader();
      fr.onload = () => {
        resolve(fr.result);
      };
      fr.onerror = reject;
      fr.readAsText(file);
    });
  },

  promise_chain(args, list, results) {
    var promise_chain_inner;
    promise_chain_inner = function(args,list, results) {
      return new Promise(function(done, errs) {
        var fn = list.shift();
        if (results === undefined) results = [];
        if (typeof fn === 'function') {
          fn(args).then(function(result) {
            results.push(result);
            console.log(result);
            promise_chain_inner(result, list, results).then(done);
          }, errs);
        } else {
          done(results);
        }
      });
    };
    promise_chain_inner(args,list,results);  
  },
  

  progress_promise_all(promises, tickCallback) {
    var len = promises.length;
    var progress = 0;

    function tick(promise) {
      promise.then(function () {
        progress++;
        tickCallback(progress, len);
      });
      return promise;
    }

    return Promise.all(promises.map(tick));
  },

  debounce(fn, delay) {
    var timeoutID = null;
    return function () {
      clearTimeout(timeoutID);
      var args = arguments;
      var that = this;
      timeoutID = setTimeout(function () {
        fn.apply(that, args);
      }, delay);
    };
  },

  FSMEx(context, initState, transitions) {
    this.context = context;
    this.initState = initState;
    this.transitions = transitions;
    this.state = initState;
    this.transition = function (actions) {
      let words = actions.split(" ");
      let action = words[0];
      var currentState = this.state;
      var p = this.transitions;
      //    console.log(
      //      "mfsm transition: " + action + " current state: " + currentState
      //    );
      for (var key of Object.keys(p)) {
        if (key == currentState) {
          if (!Object.keys(p[key]).includes(action)) {
            context["_error"](action);
            return;
          }
          for (var child_key of Object.keys(p[key])) {
            if (child_key == action) {
              /*          console.log(
                    "do: " +
                      p[key][child_key].action +
                      " " +
                      p[key][child_key].arguments
                  );*/
              if (p[key][child_key].action != null) {
                if (p[key][child_key].arguments == undefined) {
                  context[p[key][child_key].action]();
                } else {
                  context[p[key][child_key].action](
                    p[key][child_key].arguments
                  );
                }
              }
              this.state = p[key][child_key].next_state;
              /*console.log("mfsm key:" + key + ",child_key:" + child_key);
                  console.log("mfsm next state: " + this.state);*/
              break;
            }
          }
        }
      }
    };
  },
};

export default mylib;
