<template>
  <div class="container">
    <form @submit.prevent="reset_password">
      <h2>Forgot Password</h2>
      <div class="mb-3">
        <input
          type="email"
          class="form-control"
          placeholder="Email address..."
          v-model="email"
        />

        
      </div>
      <label>{{error}} </label> <br>
      <label>emailSending: {{emailSending}} </label> <br>

      <button type="submit" class="btn btn-primary">Reset</button>
    </form>
    
  </div>
</template>

<script>
import firebase from "firebase/compat/app";

export default {
  name: "ForgotPassword",
  data() {
    return {
      email: "",
      password: "",
      error: null,
      emailSending :false,
    };
  },
  methods: {
    
    reset_password() {
      if(!this.email) {
          this.error = "Please type in a valid email address."
          console.log("error");
          return;
      }
      this.error = null;
      this.emailSending = true;
      firebase
        .auth()
        .sendPasswordResetEmail(this.email)
        .then(() => {
          // Password reset email sent!
          // ..\
          console.log("password sent to " + this.email);
          this.emailSending = false;
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          // ..
          this.error = error.message
          this.emailSending = false;
          console.log(errorCode + errorMessage);
        });
    },
  },
};
</script>
