<template>
  <div id="app">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">
        <div class="d-flex flex-grow-1">
          <span class="w-100 d-lg-none d-block">
            <!-- hidden spacer to center brand on mobile --></span
          >
          <a class="navbar-brand d-none d-lg-inline-block" href="/">
            utenergy.ca
          </a>
          <a class="navbar-brand-two mx-auto d-lg-none d-inline-block" href="/">
            <img
              src="https://via.placeholder.com/40?text=utenergy.ca"
              alt="logo"
            />
          </a>
          <div class="w-100 text-right">
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          </div>
        </div>
        <b-collapse
          id="nav-collapse"
          class="navbar-collapse flex-grow-1 text-right"
          is-nav
        >
          <ul class="navbar-nav ms-auto flex-nowrap">
            <li class="nav-item">
              <a href="/tracker" class="nav-link m-2 menu-item">Job Analysis</a>
            </li>

            <li class="nav-item">
              <a href="/rd" class="nav-link m-2 menu-item">Releases</a>
            </li>

            
            <li>
              <b-nav-item-dropdown right>
                <!-- Using 'button-content' slot -->
                <template #button-content>
                  <em>User</em>
                </template>
                <b-dropdown-item
                  ><router-link to="/login"
                    >Log in</router-link
                  ></b-dropdown-item
                >
                <b-dropdown-item
                  ><router-link to="/profile"
                    >Profile</router-link
                  ></b-dropdown-item
                >
                <b-dropdown-item
                  ><router-link to="/forgotpassword"
                    >Forgot Password</router-link
                  ></b-dropdown-item
                >

                <b-dropdown-item @click="logout()">Sign Out</b-dropdown-item>
              </b-nav-item-dropdown>
            </li>
          </ul>
        </b-collapse>
      </div>
    </nav>

    <!--
    <div id="nav">
      <b-navbar toggleable="lg" sticky variant="light" type="light" flex-sm-nowrap flex-wrap>
        <b-navbar-brand href="/">
          <img
            src="https://dummyimage.com/200x60/f8f9fa/222&text=utenergy.ca"
            alt="ute"
        /></b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item>
              <router-link to="/rd" 
                >R&D Tracking</router-link
              >
            </b-nav-item>

            <b-nav-item>
              <router-link to="/tracker" 
                >Job Analysis</router-link
              >
            </b-nav-item>

          </b-navbar-nav>

          <b-navbar-nav flex-grow-1 justify-content-center>
            <b-nav-item-dropdown right>
       
              <template #button-content>
                <em>User</em>
              </template>
              <b-dropdown-item
                ><router-link to="/login">Log in</router-link></b-dropdown-item
              >
              <b-dropdown-item
                ><router-link to="/profile"
                  >Profile</router-link
                ></b-dropdown-item
              >
                            <b-dropdown-item
                ><router-link to="/forgotpassword"
                  >Forgot Password</router-link
                ></b-dropdown-item
              >

              <b-dropdown-item @click="logout()">Sign Out</b-dropdown-item>
            </b-nav-item-dropdown>

            <div class="flex-grow-1">
            
            </div>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    -->
    <router-view />
  </div>
</template>

<script>
import EngConsole from "./components/EngConsole.vue";
import firebase from "firebase/compat/app";

export default {
  name: "App",
  comments: {
    EngConsole,
  },
  methods: {
    setAuthenticated(status) {
      this.authenticated = status;
      console.log("setAuthenticated: " + status);
    },
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          alert("Successfully logged out");
          this.$router.push("/");
        })
        .catch((error) => {
          alert(error.message);
          this.$router.push("/");
        });
    },
  },
  data() {
    return {
      authenticated: false,
      mockAccount: {
        username: "nraboy",
        password: "password",
      },
    };
  },
};
</script>

<style scoped>
@import url(//fonts.googleapis.com/css?family=Open+Sans:400,300,600);
@import url(//fonts.googleapis.com/css?family=Roboto:400,100,300,500,700);
@import url(//fonts.googleapis.com/css?family=Volkhov:400italic);

#app {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#nav2 {
  background: whitesmoke;
}
#nav2 {
  padding: 0px;
  text-align: center;
}
#nav2 a {
  font-weight: bold;
  color: #2c3e50;
}
#nav2 a.router-link-exact-active {
  color: whitesmoke;
  background: #0d6efd;
  border-radius: 0.5rem;
}
</style>
