<template>
  <div v-show="show_tracker" class="tracker">
    <b-card title="Verbose" v-show="verbose" :key="job.completion">
      <b-card-text>
        completion: {{ job.completion }}, jobAnalyzing: {{ jobAnalyzing }},
        heap: {{ heap_size }}
      </b-card-text>
      <b-card-text>
        <p v-show="verbose">Max lines: {{ max_lines }}</p>
        <p>Current sheet: {{ currentSheet }}</p>

        <p>rows_length: {{ rows_length }}</p>

        <ul>
          <li v-for="col in currentColumns" :key="col.id">
            {{ col.id }} {{ col.name }}
          </li>
        </ul>
      </b-card-text>

      <b-card-text>
        <span v-show="msg.length > 0">msg: {{ msg }}</span>
      </b-card-text>
    </b-card>

    <div class="container-fluid">
      <h4>ULogger Job Analysis</h4>
      <b-progress
        :value="progress_val"
        variant="warning"
        height="2px"
        class="mb-1"
      >
      </b-progress>

      <b-alert :show="show_is_analyzing_alert" variant="info" dismissible>
        Processing, please wait.
      </b-alert>

      <b-alert
        :show="show_import_alert"
        variant="warning"
        dismissible
        fade
        data-tor="bg(#269389)"
      >
        Please use Job Panel to import csv.
      </b-alert>
      <b-alert
        :show="show_analyze_alert"
        variant="info"
        dismissible
        fade
        data-tor="bg(#269389)"
      >
        Please click Start to analyze data.
      </b-alert>
      <b-alert
        :key="job.completion"
        :show="show_congrats_alert"
        variant="success"
        dismissible
      >
        Congrats! Job analysis is done.
      </b-alert>
      <b-alert :show="show_error_alert" variant="danger" dismissible>
        Error happens.
      </b-alert>

      <table
        class="table table-bordered table-sm table-striped table-condensed"
      >
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Data Size (MB)</th>
            <th scope="col">Score</th>
            <th scope="col">Analyze</th>
            <th scope="col">Elapse Time (ms)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ job.node }}</td>
            <td>{{ job_size_in_mb }}</td>
            <td>{{ job.score }}</td>
            <td>
              <b-button
                class="mb-1"
                :class="[loading ? '' : 'active']"
                v-if="!jobIsDone && job.node != '' && job.data != ''"
                @click="start"
                variant="primary"
                size="sm"
                >{{ jobAnalyzing && !jobIsDone ? "Processing" : "Start" }}
              </b-button>
              <span v-if="jobIsDone">Done</span>
            </td>
            <td>{{ job.elapse }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="container">
      <div class="b-col">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            v-model="show_cards"
            value="show_cards"
            id="flexCheckDefault"
          />
          <label class="form-check-label" for="flexCheckDefault">
            Show Cards
          </label>
        </div>

        <div v-for="(card, index) in cards" :key="index">
          <ACard
            :cardname="card.name"
            :show_card="show_cards && card.show_card"
            :items="card.items"
            :rows="card.rows"
            :x_sel="card.x_sel"
            :y_sel="card.y_sel"
            :y_range="card.y_range"
            :y_filters="card.y_filters"
            :y_names="card.y_names"
          ></ACard>
        </div>
      </div>

      <div class="b-col">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            v-model="show_groups"
            value="show_groups"
            id="flexCheckDefault"
          />
          <label class="form-check-label" for="flexCheckDefault">
            Show Groups
          </label>
        </div>

        <Group
          @group-selected="linenumberrange"
          :lines="lines"
          v-if="show_groups && lines.length > 0"
        ></Group>
      </div>

      <div class="b-col">
        <form>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="show_slider"
              value="show_slider"
              id="flexCheckDefault"
            />
            <label class="form-check-label" for="flexCheckDefault">
              Show Slider
            </label>
          </div>
        </form>
        <ARangePicker
          v-show="show_slider"
          @sliding="timerange"
          :begin_time="begin_time"
          :end_time="end_time"
          begin="1"
          end="100"
        ></ARangePicker>
      </div>
    </div>
    <div class="container-fluid">
      <div class="b-col">
        <form>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="show_data"
              value="show_data"
              id="flexCheckDefault"
            />
            <label class="form-check-label" for="flexCheckDefault">
              Show Data
            </label>
          </div>
        </form>

        <div v-if="show_data" class="form-horizontal">
          <div class="form-group">
            <div class="row">
              <label for="filter" class="col-sm-2 control-label">Filter</label>

              <div class="col">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Search for data..."
                  v-model="filter"
                  @keydown="keydown"
                />
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <label for="cureentSheet" class="col-sm-2 control-label"
                >Current Sheet</label
              >
              <div class="col-sm-10">
                <v-select
                  :options="sheetnames"
                  v-model="currentSheet"
                  @input="onChangeSheet"
                ></v-select>
              </div>
            </div>
          </div>
          <div class="table-wrap">
            <label for="my-table">Data</label>
            <b-pagination
              :total-rows="rows_length"
              v-model="currentPage"
              :per-page="perPage"
              align="fill"
              class="my-0"
              aria-controls="my-table"
            ></b-pagination>
            <b-table
              id="my-table"
              show-empty
              striped
              hover
              :items="tableRows"
              :fields="tableFields.slice(1)"
              :per-page="perPage"
              :current-page="currentPage"
            ></b-table>
          </div>
        </div>
      </div>
    </div>

    <div v-show="show_blocs">
      <Blocs
        :show_blocs="show_blocs"
        :currentSheet="currentSheet"
        :node="node"
        @update-current-columns="updateCurrentColumns"
      ></Blocs>
    </div>

    <div v-show="verbose">
      <label>x</label>
      <select v-model="x_sel">
        <option
          v-for="(col, index) in cols"
          v-bind:value="col.id"
          v-bind:key="index"
        >
          {{ col.name }}
        </option>
      </select>
      <label>y</label>
      <select v-model="y_sel[0]">
        <option
          v-for="(col, index) in cols"
          v-bind:value="col.id"
          v-bind:key="index"
        >
          {{ col.name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import ACard from "./ACard.vue";
import ARangePicker from "./ARangePicker.vue";
import Group from "./Group.vue";
import Blocs from "./Blocs.vue";

import mtotal_transitions from "../json/mtotal_transitions.json";
import inc_transitions from "../json/inc_transitions.json";
import psi_transitions from "../json/psi_transitions.json";

import uetx_blocs from "../json/blocs/uetx.json";
import udrx_blocs from "../json/blocs/udrx.json";
import umpu_blocs from "../json/blocs/umpu.json";
import ugtx_blocs from "../json/blocs/ugtx.json";
import updr_blocs from "../json/blocs/updr.json";
import ufgi_blocs from "../json/blocs/ufgi.json";
import gdai_blocs from "../json/blocs/gdai.json";
import uhpm_blocs from "../json/blocs/uhpm.json";

import allinonecards from "../json/cards.json";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import mylib from "@/mylib";

const debounce = mylib.debounce;
const FSMEx = mylib.FSMEx;

const LISTENERS = Symbol ? Symbol() : "__listeners";
const BEGINTIMESTR = "13-Oct-2014 19:59:00";

class ProgressPromise extends Promise {
  constructor(executor) {
    super((resolve, reject) => {
      executor(
        resolve,
        reject,
        // Pass method for passing progress to listener
        (value) => {
          try {
            return this[LISTENERS].forEach((listener) => listener(value));
          } catch (error) {
            console.log("ProgressPromise catch error:" + error);
            reject(error);
          }
        }
      );
    });

    this[LISTENERS] = [];
  }
  progress(handler) {
    if (typeof handler !== "function")
      throw new Error("PROGRESS_REQUIRES_FUNCTION");
    this[LISTENERS].push(handler);
    return this;
  }
  static all(promises) {
    const results = new Array(promises.length);
    const length = promises.length;
    let resolveCount = 0;
    return new ProgressPromise((resolve, reject, progress) => {
      promises.forEach((promise, index) => {
        promise
          .then((result) => {
            results[index] = result;
            results.proportion = ++resolveCount / length;
            progress(results);
            if (resolveCount === length) resolve(results);
          })
          .catch(reject);
      });
    });
  }
  static sequence(inputs, handler) {
    const results = [];
    const length = inputs.length;
    let resolveCount = 0;
    return new ProgressPromise((resolve, reject, progress) => {
      function invokeNext() {
        handler
          .call(null, inputs[results.length])
          .then((result) => {
            results.push(result);
            results.proportion = ++resolveCount / length;
            progress(results);
            if (results.length === length) resolve(results);
            else invokeNext();
          })
          .catch(reject);
      }
      invokeNext();
    });
  }
}

function getDateString(date, format) {
  var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    getPaddedComp = function (comp) {
      return parseInt(comp) < 10 ? "0" + comp : comp;
    },
    formattedDate = format,
    o = {
      "y+": date.getFullYear(), // year
      "M+": months[date.getMonth()], //month
      "d+": getPaddedComp(date.getDate()), //day
      "h+": getPaddedComp(
        date.getHours() > 12 ? date.getHours() % 12 : date.getHours()
      ), //hour
      "H+": getPaddedComp(date.getHours()), //hour
      "m+": getPaddedComp(date.getMinutes()), //minute
      "s+": getPaddedComp(date.getSeconds()), //second
      "S+": getPaddedComp(date.getMilliseconds()), //millisecond,
      "b+": date.getHours() >= 12 ? "PM" : "AM",
    };

  for (var k in o) {
    if (new RegExp("(" + k + ")").test(format)) {
      formattedDate = formattedDate.replace(RegExp.$1, o[k]);
    }
  }
  return formattedDate;
}
function DiffSeconds(t1, t2) {
  //console.log(getDateString(new Date(t1), "d-M-y HH:mm:ss"));
  //console.log(getDateString(new Date(t2), "d-M-y HH:mm:ss"));
  var diff = Math.ceil((new Date(t2) - new Date(t1)) / 1000);
  return diff;
}
function secs2jobhours(secs) {
  return Math.ceil(secs / 3600);
}

function BlocParser(blocs) {
  this.Blocs = blocs; //umpu_blocs;
  this.getTableId = function (tableName) {
    let arr = this.Blocs["blocs"]["bloc"];
    for (var i = 0; i < arr.length; i++) {
      var tab = arr[i];
      if (
        tab["-name"] == tableName ||
        tab["-othername"] == tableName ||
        tab["-title"] == tableName
      ) {
        return parseInt(tab["-id"]);
      }
    }
    return -1;
  };
  (this.getErrorBits = function (tableId) {
    let arr = this.Blocs["blocs"]["bloc"];
    for (var i = 0; i < arr.length; i++) {
      var tab = arr[i];
      var id = parseInt(tab["-id"]);
      if (id == tableId) {
        var datas = tab["Data"]["d"];
        var bitstr = datas["-bits"];
        var array = bitstr.split(",");
        return array;
      }
    }
  }),
    (this.getTableDataCol = function (tableId, column) {
      let arr = this.Blocs["blocs"]["bloc"];
      for (var i = 0; i < arr.length; i++) {
        var tab = arr[i];
        var id = parseInt(tab["-id"]);
        if (id == tableId) {
          var datas = tab["Data"]["d"];
          var desc = "";
          if (
            datas[column] != undefined &&
            Object.prototype.hasOwnProperty.call(datas[column], "-desc")
          ) {
            desc = datas[column]["-desc"];
            if (desc != "") return desc;
          }
          if (datas[column] != undefined) return datas[column]["-name"];
          else console.log("!!!!" + tableId + column);
        }
      }
      return null;
    });
}

function mkmon(mon) {
  var res = "01";
  if (mon.match("Feb") != null) {
    res = "02";
  }
  if (mon.match("Mar")) {
    res = "03";
  }
  if (mon.match("Apr")) {
    res = "04";
  }
  if (mon.match("May")) {
    res = "05";
  }
  if (mon.match("Jun")) {
    res = "06";
  }
  if (mon.match("Jul")) {
    res = "07";
  }
  if (mon.match("Aug")) {
    res = "08";
  }
  if (mon.match("Sep")) {
    res = "09";
  }
  if (mon.match("Oct")) {
    res = "10";
  }
  if (mon.match("Nov")) {
    res = "11";
  }
  if (mon.match("Dec")) {
    res = "12";
  }
  return res;
}
function RTC(rtc) {
  var aaa = rtc.split(/[:, -]/);
  var day = aaa[0];
  var mon = aaa[1];
  var year = aaa[2];
  var hour = aaa[3];
  var min = aaa[4];
  var sec = aaa[5];
  var str =
    year + "-" + mkmon(mon) + "-" + day + " " + hour + ":" + min + ":" + sec;
  this.t = Date.parse(str);
  this.year = year;
  this.ValidYearSince = 2015;
}

export default {
  name: "Tracker",
  components: {
    vSelect,
    Group,
    Blocs,
    ACard,
    ARangePicker,
  },
  props: {
    eng_job: {
      type: Object,
    },
    handle_1979: {
      type: Boolean,
    },
    toggle: {
      type: String,
    },
    score: {
      type: Number,
    },
    show_blocs: {
      type: Boolean,
    },
    show_card: {
      Boolean,
    },
    set_target: {
      type: Object,
    },
    get_target: {
      type: Object,
    },

    show_tracker: {
      type: Boolean,
    },
    show_plot: {
      type: Boolean,
    },
    verbose: {
      type: Boolean,
    },
  },
  created: function () {
    var that = this;
    this.cards.forEach((val) => {
      that.$watch(() => {
        val, that.handleChange, { deep: true };
      });
    });
  },
  watch: {
    eng_job: function (job) {
      this.job = job;
      this.clear();
      this.init_cards(this.job.node);
      console.log("watch eng_job  node:", job.node);
      console.log("watch eng_job  data length:", job.data.length);
    },
    show_jobpanel: function (toggle) {
      console.log("watch job panel", toggle);
    },
    node: function (newNode) {
      console.log("watch node:" + newNode);
      this.init_cards(newNode);
    },
    filter: debounce(function (filt) {
      console.log(
        "!! watch filter:" +
          filt +
          ",filter text:" +
          this.filter +
          ",total rows:" +
          this.currentRows.length
      );
      this.debouncedFilter = filt;
      this.filterTable();
    }, 500),
    get_target: function (newTarget) {
      console.log("get target watch: " + newTarget.name);
      this.msg = "get target";
      if (newTarget.name.match(/cards/i)) {
        this.msg = newTarget.name + ": ";
        for (var i = 0; i < this.cards.length; i++) {
          this.msg += this.cards[i].name + " ";
        }
      }
      if (newTarget.name.match(/job.begin[_]?time/i)) {
        console.log(this.begin_time);
        this.msg = newTarget.name + ": " + this.begin_time;
      }
      if (newTarget.name.match(/job.end[_]?time/i)) {
        console.log(this.end_time);
        this.msg = newTarget.name + ": " + this.end_time;
      }

      if (newTarget.name.match(/job.start[_]?time/i)) {
        console.log(this.start_time);
        this.msg = newTarget.name + ": " + this.start_time;
      }
      if (newTarget.name.match(/job.stop[_]?time/i)) {
        console.log(this.stop_time);
        this.msg = newTarget.name + ": " + this.stop_time;
      }
      this.$emit("keyup.enter", this.msg);
    },
    set_target: function (newTarget) {
      console.log("set target watch" + newTarget.name);
      if (newTarget.name.match(/job.start[_]?time/i)) {
        console.log(newTarget.value);
        console.log(this.start_time);
        this.start_time = newTarget.value;
      }
      if (newTarget.name.match(/job.stop[_]?time/i)) {
        console.log(this.stop_time);
        console.log(newTarget.value);
        this.stop_time = newTarget.value;
      }
      this.msg = "done";
      this.$emit("keyup.enter", this.msg);
    },

    toggle: {
      handler(val) {
        console.log("tracker: toggle " + val);
        this.do_toggle(this.toggle);
        this.$emit("keyup.enter", val);
      },
      deep: true,
    },
    handleChange(newVal, oldVal) {
      console.log("handleChanged: old:" + oldVal + " ,new: " + newVal);
    },
  },
  mounted: function () {
    console.log("tracker; mounted");
  },
  computed: {
    job_is_done() {
      return this.jobAnalyzing == false && this.job.completion == 5;
    },
    job_size_in_mb() {
      if (this.job.size == null || this.job.size == "") return "";
      return parseFloat(this.job.size / Math.pow(1024, 2)).toFixed(2);
    },
    heap_size() {
      if (mylib.checkBrowser() == "Chrome")
        return (
          parseFloat(
            performance.memory.usedJSHeapSize / Math.pow(1024, 2)
          ).toFixed(2) + "MB"
        );
      return "";
    },
    show_import_alert() {
      if (this.job.node == "") return true;
      return false;
    },
    show_is_analyzing_alert() {
      return this.jobAnalyzing;
    },

    show_analyze_alert() {
      if (this.jobAnalyzing) return false;
      if (this.job.node == "") return false;
      if (this.job.score == 0) return true;
      return false;
    },
    show_error_alert() {
      if (this.job.node == "") {
        return false;
      }
      if (this.job.score < 0) return true;
      return false;
    },

    show_congrats_alert() {
      if (this.jobAnalyzing) return false;
      if (this.job.completion == 0) return false;
      if (this.job.node == "") return false;
      if (this.job.score > 0) return true;
      return false;
    },
    max_lines() {
      return this.lines != null ? this.lines.length : 0;
    },
    cols() {
      let cols = [];
      for (var i = 0; i < this.maxcols; i++) {
        cols.push[i.toString()];
      }
      return cols;
    },
    fields3() {
      // for buefy
      let fields = [];
      for (var i = 0; i < this.currentColumns.length; i++) {
        let rec = {
          field: i.toString(),
          label: this.currentColumns[i].name,
        };
        fields.push(rec);
      }
      console.log(
        "tracker: computed fields " +
          fields.length +
          "currentSheet: " +
          this.currentSheet +
          "," +
          JSON.parse(JSON.stringify(new Date()))
      );
      return fields;
    },

    tableFields() {
      let fields = [];
      for (var i = 0; i < this.currentColumns.length; i++) {
        let rec = {
          key: i.toString(),
          label: this.currentColumns[i].name,
        };
        fields.push(rec);
      }

      return fields;
    },
    tableRows() {
      var rows = [];
      const start = Date.now();

      console.log(
        "tracker: computed rows, debouncedFilter:" +
          this.debouncedFilter +
          ",length:" +
          this.currentRows.length
      );

      this.currentRows.forEach((row) => {
        if (row.show) {
          rows.push(row.cells);
        }
      });
      const end = Date.now();
      const elapse = end - start;

      console.log(
        "tracker: computed rows " +
          rows.length +
          "currentSheet: " +
          this.currentSheet,
        "elapse:" + elapse + "ms"
      );
      return rows;
    },
  },
  methods: {
    log(message, rest) {
      if (this.verbose) {
        console.log(message, rest);
      }
    },
    init_cards(newNode) {
      for (var i in allinonecards) {
        var cards = allinonecards[i];

        if (cards["name"].toLowerCase() == newNode.toLowerCase()) {
          this.cards = cards["cards"];
        }
      }
    },
    _start() {
      let self = this;
      let val = self.makeLines();
      if (self.cards.length == 0) {
        self.init_cards(self.node);
      }

      const start = Date.now();
      console.log("!! analyze start:  lines" + val.length);

      self.lines = val;

      let task0 = self.task0;
      const task_lines = self.task_lines;
      var task_cards = self.processCardsTask;
      var task_flowon = self.task_flowon_hours;
      var task_psi = self.task_psi;
      var task_inc = self.task_inc;
      var task_mtot = self.task_mtot;
      task_lines.bind(self);
      task_cards.bind(self);
      task_flowon.bind(self);
      task_psi.bind(self);
      task_inc.bind(self);
      task0.bind(self);
      task_mtot.bind(self);

      return task_lines()
        .then((res) => {
          self.log("task_lines... result:", res);
          self.currentRows = res;
          self.updateInfoCard(
            new RTC(self.begin_time).t,
            new RTC(self.end_time).t
          );

          if (res.length > 0) self.job.score++;
          else {
            console.log("task_lines.score", 0);
          }
          setTimeout(() => {
            const end_t1 = Date.now();
            const elapse_t1 = end_t1 - start;
            self.updateProgress(1, 5);
            self.job.completion = 1;

            console.log("task_lines.done", elapse_t1 + " ms");
          }, 0);
        })
        .then(() => {
          self.log("start task_cards");
          return task_cards();
        })
        .then((res) => {
          self.log("task_cards... result:", res);
          if (res.length > 0) {
            self.job.score++;
          } else {
            console.log("task_cards.score", 0);
          }

          setTimeout(() => {
            const end_t2 = Date.now();
            const elapse_t2 = end_t2 - start;
            self.updateProgress(2, 5);
            self.job.completion = 2;
            self.log("task_cards.done", elapse_t2 + " ms");
          }, 0);
        })
        .then((res) => {
          self.log("start task_psi... res: ", res);
          return task_psi();
        })
        .then((res) => {
          self.log("task_psi... result: ", res);
          self.task_psi_result = res;
        })
        .then(() => {
          return task_mtot();
        })
        .then((res) => {
          console.log("task_mtot... result: ", res);
          self.task_mtot_result = res;
        })
        .then(() => {
          if (
            self.task_mtot_result != null &&
            self.task_mtot_result.score > 0
          ) {
            let res = self.task_mtot_result;
            if (Object.prototype.hasOwnProperty.call(res, "casings")) {
              let res_casings = res.casings;
              res_casings.forEach((casing) => {
                if (casing.start != null)
                  console.log(
                    "casing start:",
                    getDateString(new Date(casing.start), "d-M-y HH:mm:ss")
                  );
                if (casing.end != null)
                  console.log(
                    "casing end:",
                    getDateString(new Date(casing.end), "d-M-y HH:mm:ss")
                  );
              });
              let runs = [];
              if (res_casings.length >= 2) {
                let end = res_casings[1].start;
                if (res_casings[1].end != null) {
                  end = res_casings[1].end;
                }
                let z_start = res_casings[0].start;
                let diff = DiffSeconds(z_start, end);
                let run = {
                  start: getDateString(new Date(z_start), "d-M-y HH:mm:ss"),
                  stop: getDateString(new Date(end), "d-M-y HH:mm:ss"),
                  diff: diff,
                };
                runs.push(run);
              }
              self.job.runs = runs;
              self.updateRunCards(runs);
            }
          } else {
            let res = self.task_psi_result;
            const end_t4 = Date.now();
            const elapse_t4 = end_t4 - start;
            setTimeout(() => {
              self.updateProgress(3, 5);
              self.job.completion = 3;
            }, 0);
            if (res != null && res.score != 0) self.job.score++;
            else {
              self.log("task4.score", 0);
            }
            if (
              res != undefined &&
              Object.prototype.hasOwnProperty.call(res, "runs")
            ) {
              console.log("task_psi has runs");
              self.job.runs = res.runs;
              self.updateRunCards(res.runs);
            }
            if (
              res != undefined &&
              Object.prototype.hasOwnProperty.call(res, "name")
            ) {
              console.log(res.name);
              if (res.name.toLowerCase() == "flow on hours") {
                console.log("flow on hours: ", res.value);
                self.updateCardItem("info", "flow on hours", res);
              }
              if (res.name.toLowerCase() == "downhole hours") {
                console.log("downhole on hours: ", res.value);
                if (res.score >= 80)
                  self.updateCardItem("info", "downhole hours", res);
              }
            }

            console.log("task4.done", elapse_t4 + " ms");
          }
        })

        .then(() => {
          if (self.job.runs.length > 0) {
            const start_time = self.job.runs[0].start;
            const stop_time = self.job.runs[0].stop;
            return task_flowon(start_time, stop_time);
          }
        })
        .then((res) => {
          console.log("task_flowon... run1 result: ", res);
          if (res != null && res.score != 0) self.job.score++;
          else {
            console.log("task_flowon.score", 0);
          }
          if (
            res != undefined &&
            Object.prototype.hasOwnProperty.call(res, "name")
          ) {
            console.log(res.name);
            if (res.name.toLowerCase() == "flow on hours") {
              console.log("flow on hours: ", res.value);
              self.updateCardItem("run1", "flow on hours", res);
            }
          }

          setTimeout(() => {
            const end_t3 = Date.now();
            const elapse_t3 = end_t3 - start;
            console.log("task_flowon.done", elapse_t3 + " ms", self.job.score);
            self.updateProgress(5, 5);
            self.job.completion = 5;
          }, 0);
        })
        .then(() => {
          if (self.job.runs.length > 1) {
            const start_time = self.job.runs[1].start;
            const stop_time = self.job.runs[1].stop;
            return task_flowon(start_time, stop_time);
          }
        })
        .then((res) => {
          console.log("task_flowon run2 if any... result: ", res);
          if (res != undefined && res != null && res.score != 0)
            self.job.score++;
          else {
            console.log("task_flowon.score run2", 0);
          }
          if (
            res != undefined &&
            Object.prototype.hasOwnProperty.call(res, "name")
          ) {
            console.log(res.name);
            if (res.name.toLowerCase() == "flow on hours") {
              console.log("flow on hours: ", res.value);
              self.updateCardItem("run2", "flow on hours", res);
            }
          }

          setTimeout(() => {
            const end_t3 = Date.now();
            const elapse_t3 = end_t3 - start;
            console.log(
              "task_flowon.done run2",
              elapse_t3 + " ms",
              self.job.score
            );
            self.updateProgress(5, 5);
            self.job.completion = 5;
          }, 0);
        })

        .then(() => {
          setTimeout(() => {
            const end_tot = Date.now();
            const elapse_tot = end_tot - start;
            self.job.elapse = elapse_tot;
            self.msg = "done";

            console.log("task all done", elapse_tot + " ms", self.job);
            console.log("set jobAnalyzing to false");
            self.jobAnalyzing = false;
            self.loading = false;
            self.jobIsDone = true;
          }, 1000);
        })

        .catch((err) => {
          self.msg = err.message;

          console.log(
            "catch something",
            err.message,
            JSON.parse(JSON.stringify(new Date()))
          );
        });
    },
    start() {
      if (this.jobAnalyzing) return;
      let self = this;
      self.jobIsDone = false;
      self.jobAnalyzing = true;
      self.loading = true;
      self.job.score = 0;
      self.progress_val = 0;
      self.job.completion = 0;

      setTimeout(() => {
        self._start();
      }, 0);
    },

    task0() {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve("done");
        }, 1000);
      });
    },
    updateProgress(v, len) {
      const old_v = v;
      v = (v * 100) / len;
      if (v > 100) v = 100;
      if (v < 0) v = 0;
      this.progress_val = v;
      this.width = v;

      console.log("updateProgress " + old_v + ": " + this.width + "%");
    },
    makeLines() {
      this.node = this.job.node.toUpperCase();

      let lines = this.job.data.split("\n");
      let res = [];
      const year = new Date().getFullYear() + 1;
      const pattern_1979 = new RegExp(/(Jan|Feb)-1979/g);
      const pattern_2021 = new RegExp(/(Jan|Feb)-2021/g);
      lines.forEach((line) => {
        if (this.node == "GDAI") {
          if (line.match(pattern_2021)) {
            line = "";
          }
        } else {
          if (this.handle_1979) {
            if (line.match(pattern_1979)) {
              line = line.replace(pattern_1979, "$1-" + year);
            } else {
              line = "";
            }
          }
        }
        if (line != "") res.push(line);
      });
      console.log("make lines: " + res.length);
      return res;
    },
    onselectnode(item) {
      console.log("tracker select node:", item);
      this.job = item;
      this.clear();
      this.init_cards(this.job.node);
    },
    linenumberrange(item) {
      console.log("tracker line number range:" + item.start + " " + item.stop);
      this.start_nr = item.start;
      this.stop_nr = item.stop;

      debounce(this.filterTable(), 500);
    },
    timerange(item) {
      console.log("tracker time range:" + item.start + " " + item.stop);
      this.start_time = item.start;
      this.stop_time = item.stop;
      debounce(this.trueLongTask(), 500);
    },
    filterTable() {
      let len = 0;
      let check_rtc = true;
      let check_nr = true;
      if (this.stop_nr == 0 || this.start_nr <= this.stop_nr) {
        check_nr = false;
      }

      if (this.start_time == "") check_rtc = false;

      if (check_rtc) {
        const start_time_rtc = new RTC(this.start_time);
        const stop_time_rtc = new RTC(this.stop_time);
        const begin_time_rtc = new RTC(this.begin_time);
        const end_time_rtc = new RTC(this.end_time);
        if (
          start_time_rtc.t == begin_time_rtc.t &&
          stop_time_rtc.t == end_time_rtc.t
        ) {
          check_rtc = false;
        }
      }

      for (const i of this.currentRows) {
        if (i.sheetname != this.currentSheet) i.show = false;
        else {
          i.show = this.debouncedFilter
            ? i.data.indexOf(this.debouncedFilter) > -1
            : true;
          if (i.show && check_rtc) {
            const start_time_rtc = new RTC(this.start_time);
            const stop_time_rtc = new RTC(this.stop_time);

            var rtc = new RTC(i.cells[1]);
            if (rtc.t < start_time_rtc.t) i.show = false;
            if (rtc.t > stop_time_rtc.t) i.show = false;
          }
          if (i.show && check_nr) {
            const start_nr = this.start_nr;
            const stop_nr = this.stop_nr;
            if (i.nr < start_nr) i.show = false;
            if (i.nr > stop_nr) i.show = false;
          }

          len++;
        }
      }
      this.rows_length = len;
      console.log(
        "filter length: " +
          this.rows_length +
          "," +
          this.start_time +
          ":" +
          this.stop_time
      );
    },
    keydown: function (event) {
      console.log("what" + event);
      event.stopImmediatePropagation();
    },
    longTask() {
      var that = this;
      return new ProgressPromise((resolve, reject, progress) => {
        that.msg = "tada";
        setTimeout(() => progress(25), 250);

        setTimeout(() => progress(50), 500);

        setTimeout(() => progress(75), 750);

        setTimeout(resolve, 1000);
      });
    },
    trueLongTask() {
      let self = this;
      var task2 = self.processCardsTask;
      task2.bind(self);
      ProgressPromise.all([task2()])
        .progress((results) => {
          let n = Math.round(results.proportion * 100);
          self.loading = true;
          self.progress_val = n;
          self.msg = "progress tasks:" + self.progress_val + "%";

          console.log(
            "progress tasks:" + n + "%",
            JSON.parse(JSON.stringify(new Date()))
          );
          if (results.proportion == 1) {
            self.loading = false;
          }
        })
        .then((results) => {
          self.loading = false;
          self.msg = "update cards and calculating is complete.";
          console.log(
            "Resolved",
            results,
            JSON.parse(JSON.stringify(new Date()))
          );
        });
    },
    updateRunCards(runs) {
      if (this.node != "UMPU") return;
      let self = this;

      runs.forEach((run, index) => {
        var runs_items = [];
        var runs_starttime = {
          name: "Start",
          value: run.start,
          enable: true,
        };
        var runs_stoptime = {
          name: "Stop",
          value: run.stop,
          enable: true,
        };
        var runs_diff = {
          name: "Downhole Hours",
          value: secs2jobhours(run.diff),
          enable: true,
        };
        var runs_flowon = {
          name: "Flow On Hours",
          value: 0,
          enable: true,
        };

        var runs_score = {
          name: "Score",
          value: run.score,
          enable: true,
        };
        runs_items.push(runs_starttime);
        runs_items.push(runs_stoptime);
        runs_items.push(runs_diff);
        runs_items.push(runs_flowon);
        if (self.verbose) runs_items.push(runs_score);

        self.setCardItems("run" + parseInt(index + 1), runs_items);
        self.showCard("run" + parseInt(index + 1), true);
      });
    },
    updateInfoCard(_begintime, _endtime) {
      var diffhours = Math.ceil(
        (new Date(_endtime) - new Date(_begintime)) / (1000 * 60 * 60)
      );
      var info_begintime = {
        name: "Begin",
        value: getDateString(new Date(_begintime), "d-M-y HH:mm:ss"),
        enable: true,
      };
      var info_endtime = {
        name: "End",
        value: getDateString(new Date(_endtime), "d-M-y HH:mm:ss"),
        enable: true,
      };
      var info_jobhours = {
        name: "Log Hours",
        value: diffhours,
        enable: true,
      };

      var info_items = [info_begintime, info_endtime, info_jobhours];
      this.setCardItems("info", info_items);
    },
    updateDiagCard(line) {
      let items = this.getCardItems("Diag");
      const regex = /summary/g;
      const found = line.match(regex);
      if (found) {
        let arr = line.split(",");
        let name = arr[2];
        let val = arr[3];
        let i;
        let found = false;
        for (i = 0; i < items.length; i++) {
          if (items[i].name == name) {
            items[i] = {
              name: name,
              value: val,
              enable: true,
            };
            found = true;
            break;
          }
        }
        if (!found) {
          items.push({
            name: name,
            value: val,
            enable: true,
          });
        }
      }
      this.setCardItems("diag", items);
    },

    task_lines() {
      var that = this;
      that.msg = "t1: processing lines...";
      that.loading = true;
      return new Promise((resolve) => {
        let rows = [];
        that.clear();
        that.start_time = BEGINTIMESTR;
        that.end_time = BEGINTIMESTR;
        if (this.lines.length == 0) {
          resolve();
        }
        let blocs = umpu_blocs;
        if (that.node == "UDRX") {
          blocs = udrx_blocs;
        }
        if (that.node == "UGTX") {
          blocs = ugtx_blocs;
        }
        if (that.node == "UPDR") {
          blocs = updr_blocs;
        }
        if (that.node == "UFGI") {
          blocs = ufgi_blocs;
        }
        if (that.node == "GDAI") {
          blocs = gdai_blocs;
        }
        if (that.node == "UHPM") {
          blocs = uhpm_blocs;
        }

        if (that.node == "UETX") {
          blocs = uetx_blocs;
        }

        var bloc_parser = new BlocParser(blocs);
        if (that.node == "UMPU" && that.verbose) {
          console.log("node: " + that.node);
          var stringInfo_id = bloc_parser.getTableId("StringInfo");
          console.log(stringInfo_id);
          var c1Name = bloc_parser.getTableDataCol(stringInfo_id, 1);
          console.log("stringinfo: 1_" + c1Name); // upsw fw

          var c4Name = bloc_parser.getTableDataCol(stringInfo_id, 4);
          console.log("stringinfo: 4_" + c4Name); // updr
        }

        var start_time_rtc = new RTC(that.start_time);
        var stop_time_rtc = new RTC(that.stop_time);
        if (that.verbose) console.log(stop_time_rtc.t);
        var isbegin = true;
        var _begintime = 0;
        var _endtime = 0;
        var prevtime = 0;

        let ind = 0;
        that.lines.forEach((line) => {
          let line2 = line.replace(/\r/g, "").replace(/,$/, "");
          var cells = line2.split(",");
          var sheetname = cells[0].trim(" ");
          var table_item = {
            sheetname: sheetname,
            nr: ind,
            data: line,
            cells: cells,
            show: true,
          };
          if (sheetname != "" && that.sheetnames.indexOf(sheetname) < 0) {
            if (sheetname.match(/Diag/g) == null) {
              that.sheetnames.push(sheetname);

              if (that.currentSheet == "") {
                that.currentSheet = sheetname;
              }
            }
          }
          if (sheetname.match(/Diag/g)) {
            that.updateDiagCard(line);
          }

          if (that.node == "UMPU" && sheetname.match(/Diag/g) == null) {
            that.updateCard(bloc_parser, "firmware", cells);
            that.updateCard(bloc_parser, "tool", cells);
          }
          if (that.node == "UGTX" && sheetname.match(/Diag/g) == null) {
            that.updateCard(bloc_parser, "tool", cells);
          }
          if (that.node == "UHPM" && sheetname.match(/Diag/g) == null) {
            that.updateCard(bloc_parser, "tool", cells);
          }

          if (cells.length > 1) rows.push(table_item);

          var rtc = cells[1];
          if (
            sheetname.match(/Diag/g) == null &&
            rtc != undefined &&
            rtc != ""
          ) {
            var rtcObj = new RTC(rtc);
            var t = rtcObj.t;
            if (rtcObj.t > start_time_rtc.t) {
              if (rtcObj.year > rtcObj.ValidYearSince && isbegin) {
                isbegin = false;
                prevtime = t;
                _begintime = t;
                _endtime = t;
                console.log("begin?" + t);
              }
              if (isbegin == false) {
                if (t > prevtime) {
                  _endtime = t;
                }

                prevtime = t;
              }
            }
          }

          ind++;
        });

        that.start_time = getDateString(new Date(_begintime), "d-M-y HH:mm:ss");
        that.stop_time = getDateString(new Date(_endtime), "d-M-y HH:mm:ss");
        that.begin_time = that.start_time;
        that.end_time = that.stop_time;
        setTimeout(() => {
          resolve(rows);
        }, 0);
      });
    },
    clear() {
      this.jobIsDone = false;
      this.completion = 0;
      this.progress_val = 0;
      this.currentRows = [];
      this.sheetnames = [];
      this.currentColumns = [];
      this.currentSheet = "";
    },
    task_flowon_hours(start_time, stop_time) {
      var that = this;
      that.msg = "t3: processing flowon hours...";

      return new Promise((resolve) => {
        if (that.node != "UMPU") {
          setTimeout(() => {
            resolve();
          }, 0);
        }

        var start_time_rtc = new RTC(start_time);
        var stop_time_rtc = new RTC(stop_time);
        var blocs = umpu_blocs;
        if (that.node == "UDRX") {
          blocs = udrx_blocs;
        }
        if (that.node == "UGTX") {
          blocs = ugtx_blocs;
        }
        if (that.node == "UPDR") {
          blocs = updr_blocs;
        }
        if (that.node == "UFGI") {
          blocs = ufgi_blocs;
        }

        if (that.node == "UETX") {
          blocs = uetx_blocs;
        }

        var bloc_parser = new BlocParser(blocs);

        if (that.node != "UMPU") {
          resolve();
        }
        if (that.lines.length == 0) {
          resolve();
        }
        var table_error_5 = [];
        var tot1 = 0;
        var tot2 = 0;

        var ind = 0;
        for (var i = 0; i < that.lines.length; i++) {
          var line = that.lines[i];
          var cells = line.split(",");
          var sheetname = cells[0].trim(" ");
          var table_id = bloc_parser.getTableId(sheetname);

          if (table_id == 5) {
            var table_item = {
              sheetname: sheetname,
              nr: i,
              data: line,
              cells: cells,
            };

            table_error_5.push(table_item);
          }
        }

        var on_bit = 11;
        var off_bit = 10;
        ind = 0;
        var indprev = 0;
        var tevent = 0;
        var tprev = 0;
        var array_onoff = [];
        var error_array = bloc_parser.getErrorBits(5);
        console.log(error_array);
        var bit = "0";
        for (var err_row = 0; err_row < table_error_5.length; err_row++) {
          var rtc = new RTC(table_error_5[err_row].cells[1]);
          if (rtc.t < start_time_rtc.t) continue;
          if (rtc.t > stop_time_rtc.t) break;

          //setTimeout(() => progress(err_row * 100/table_error_5.length), 10);

          var error_low = parseInt(table_error_5[err_row].cells[2], 16);
          var error_high = parseInt(table_error_5[err_row].cells[3], 16);
          var err_val = (error_high << 16) | error_low;
          if ((err_val & (1 << on_bit)) != 0) {
            ind = 1;
            var cells0 = table_error_5[err_row].cells.slice(0);
            cells0.push(bit);
            array_onoff.push({
              nr: table_error_5[err_row].nr,
              data: table_error_5[err_row].data,
              cells: cells0,
            });

            cells = table_error_5[err_row].cells.slice(0);
            bit = "2";

            cells.push(bit);
            array_onoff.push({
              nr: table_error_5[err_row].nr,
              data: table_error_5[err_row].data,
              cells: cells,
            });
          } else if ((err_val & (1 << off_bit)) != 0) {
            ind = 2;
            cells0 = table_error_5[err_row].cells.slice(0);
            cells0.push(bit);
            array_onoff.push({
              nr: table_error_5[err_row].nr,
              data: table_error_5[err_row].data,
              cells: cells0,
            });

            cells = table_error_5[err_row].cells.slice(0);
            bit = "1";
            cells.push(bit);
            array_onoff.push({
              nr: table_error_5[err_row].nr,
              data: table_error_5[err_row].data,
              cells: cells,
            });
          }

          tevent = rtc.t;
          var diff = DiffSeconds(tprev, tevent);
          if (that.verbose && 0)
            console.log(
              "rtc:" +
                getDateString(new Date(rtc.t), "d-M-y HH:mm:ss") +
                "ind:" +
                ind +
                ",diff:" +
                diff +
                ",tot1:" +
                tot1 +
                ",tot2:" +
                tot2
            );
          if (indprev == 1) {
            tot1 = tot1 + diff;
          }
          if (indprev == 2) {
            tot2 = tot2 + diff;
          }
          tprev = tevent;
          indprev = ind;
        }
        console.log("tot1:" + secs2jobhours(tot1));
        console.log("tot2: " + secs2jobhours(tot2));
        let n_flowonhours = secs2jobhours(tot1);
        console.log("flowon hours" + n_flowonhours);
        let score = 0;
        if (n_flowonhours > 0) score = 1;

        var info_flowonhours = {
          name: "Flow on Hours",
          value: n_flowonhours,
          enable: true,
          score: score,
        };

        setTimeout(() => {
          resolve(info_flowonhours);
          console.log("task_flowon calculate flowon hours:  promise resolved");
        }, 0);
      });
    },

    _task_psi_init() {
      this.t4_stat.res = [];
    },
    _task_psi_on() {
      if (this.t4_stat.off.t.length > 0) {
        let t1 = this.t4_stat.off.t[0];
        let n = this.t4_stat.off.t.length;
        let t2 = this.t4_stat.off.t[n - 1];
        let diff = DiffSeconds(t1, t2);
        console.log("task_psi_off duration: " + diff);
        this.t4_stat.off.t = [];
      }

      console.log("task_psi_on");
      this.t4_stat.on.t.push(this.t4_data.t);
    },
    _task_psi_off() {
      if (this.t4_stat.on.t.length > 0) {
        let t1 = this.t4_stat.on.t[0];
        let n = this.t4_stat.on.t.length;
        let t2 = this.t4_stat.on.t[n - 1];
        let diff = DiffSeconds(t1, t2);
        console.log("task_psi_on duration: " + diff);
        this.t4_stat.on.t = [];
        if (diff > 3600) {
          let str1 = getDateString(new Date(t1), "d-M-y HH:mm:ss");
          let str2 = getDateString(new Date(t2), "d-M-y HH:mm:ss");
          this.t4_stat.res.push({
            start: str1,
            stop: str2,
            diff: diff,
            score: 100,
          });
          console.log("task_psi_on res: ", this.t4_stat.res);
        }
      }
      this.t4_stat.off.t.push(this.t4_data.t);
    },
    _task_psi_stay_on() {
      this.t4_stat.on.t.push(this.t4_data.t);
    },
    _task_psi_stay_off() {
      this.t4_stat.off.t.push(this.t4_data.t);
    },

    task_psi() {
      var that = this;
      return new Promise((resolve) => {
        console.log("task_psi: calculate downhole hours (psi): start");
        if (that.lines.length == 0) {
          resolve();
        }
        if (that.node != "UMPU") {
          resolve();
        }

        let table_meas = [];
        const blocs = umpu_blocs;
        const start_time_rtc = new RTC(that.start_time);
        const stop_time_rtc = new RTC(that.stop_time);

        var bloc_parser = new BlocParser(blocs);
        for (var i = 0; i < that.lines.length; i++) {
          var line = that.lines[i];
          var cells = line.split(",");
          var sheetname = cells[0].trim(" ");
          var table_id = bloc_parser.getTableId(sheetname);

          if (table_id == 16) {
            var table_item = {
              sheetname: sheetname,
              nr: i,
              data: line,
              cells: cells,
            };

            table_meas.push(table_item);
          }
        }
        const STATE_ON = "on";
        const STATE_OFF = "off";
        const STATE_IDLE = "idle";

        let state = STATE_IDLE;

        var psiFSM = new FSMEx(that, "idle", psi_transitions);
        psiFSM.transition("init");

        for (var meas_row = 0; meas_row < table_meas.length; meas_row++) {
          var rtc = new RTC(table_meas[meas_row].cells[1]);
          if (rtc.t < start_time_rtc.t) continue;
          if (rtc.t > stop_time_rtc.t) break;
          psiFSM.context.t4_data = {
            t: rtc.t,
          };
          state = psiFSM.state;

          let psi = parseInt(table_meas[meas_row].cells[6]);
          if (psi > 500) {
            if (state != STATE_ON) {
              psiFSM.transition("on");
            } else {
              psiFSM.transition("stay_on");
            }
          } else {
            if (state != STATE_OFF) {
              psiFSM.transition("off");
            } else {
              psiFSM.transition("stay_off");
            }
          }
        }
        let runs = that.t4_stat.res;
        var info_downholehours = {
          name: "Downhole Hours",
          enable: true,
          option: "psi",
          runs: runs,
        };
        setTimeout(() => {
          resolve(info_downholehours);
        }, 0);
      });
    },
    _t5_update_to_bank_or_stay_bank() {
      this.t5_stat.bank.t.push(this.t5_data.t);
      this.t5_stat.bank.inc.push(this.t5_data.inc);
      this.t5_stat.bank.gtot.push(this.t5_data.mtot);
      this.t5_stat.bank.mtot.push(this.t5_data.mtot);
    },
    _t5_update_to_bank2_or_stay_bank2() {
      this.t5_stat.bank2.t.push(this.t5_data.t);
      this.t5_stat.bank2.inc.push(this.t5_data.inc);
      this.t5_stat.bank2.gtot.push(this.t5_data.mtot);
      this.t5_stat.bank2.mtot.push(this.t5_data.mtot);
    },

    _t5_update_to_tripin_or_stay_tripin() {
      this.t5_stat.tripin.t.push(this.t5_data.t);

      this.t5_stat.tripin.inc.push(this.t5_data.inc);
      this.t5_stat.tripin.gtot.push(this.t5_data.mtot);
      this.t5_stat.tripin.mtot.push(this.t5_data.mtot);
    },

    _t5_update_to_tripout_or_stay_tripout() {
      this.t5_stat.tripout.t.push(this.t5_data.t);

      this.t5_stat.tripout.inc.push(this.t5_data.inc);
      this.t5_stat.tripout.gtot.push(this.t5_data.mtot);
      this.t5_stat.tripout.mtot.push(this.t5_data.mtot);
    },
    _t5_update_to_downhole_or_stay_downhole() {
      this.t5_stat.downhole.t.push(this.t5_data.t);

      this.t5_stat.downhole.inc.push(this.t5_data.inc);
      this.t5_stat.downhole.gtot.push(this.t5_data.mtot);
      this.t5_stat.downhole.mtot.push(this.t5_data.mtot);
    },

    t5_to_report_bank() {
      let t1 = this.t5_stat.bank.t[0];
      let n = this.t5_stat.bank.t.length;
      let t2 = this.t5_stat.bank.t[n - 1];
      let diff = DiffSeconds(t1, t2);
      let str1 = getDateString(new Date(t1), "d-M-y HH:mm:ss");
      let str2 = getDateString(new Date(t2), "d-M-y HH:mm:ss");
      let score = 0;
      if (diff > 600) score = 20;
      this.t5_stat.bank.score = score;
      if (this.verbose)
        console.log(
          "to_check diff bank: " + diff + ",start:" + str1 + ",end:" + str2
        );
      return diff;
    },

    t5_to_report_tripin(set_score) {
      let t1 = this.t5_stat.tripin.t[0];
      let n = this.t5_stat.tripin.t.length;
      let t2 = this.t5_stat.tripin.t[n - 1];
      let diff = DiffSeconds(t1, t2);
      let str1 = getDateString(new Date(t1), "d-M-y HH:mm:ss");
      let str2 = getDateString(new Date(t2), "d-M-y HH:mm:ss");
      let score = 0;
      if (set_score) {
        if (diff > 3600) score = 20;
        this.t5_stat.tripin.score = score;
      }
      if (this.verbose && 0)
        console.log(
          "to_check diff tripin: " + diff + ",start:" + str1 + ",end:" + str2
        );
      return diff;
    },
    t5_to_report_downhole() {
      let t1 = this.t5_stat.downhole.t[0];
      let n = this.t5_stat.downhole.t.length;
      let t2 = this.t5_stat.downhole.t[n - 1];
      let diff = DiffSeconds(t1, t2);
      let str1 = getDateString(new Date(t1), "d-M-y HH:mm:ss");
      let str2 = getDateString(new Date(t2), "d-M-y HH:mm:ss");

      let score = 0;
      if (diff > 3600) score = 20;
      this.t5_stat.downhole.score = score;

      console.log(
        "to_check diff downhole: " + diff + ",start:" + str1 + ",end:" + str2
      );
      return diff;
    },
    t5_to_report_tripout() {
      let t1 = this.t5_stat.tripout.t[0];
      let n = this.t5_stat.tripout.t.length;
      let t2 = this.t5_stat.tripout.t[n - 1];
      let diff = DiffSeconds(t1, t2);
      let str1 = getDateString(new Date(t1), "d-M-y HH:mm:ss");
      let str2 = getDateString(new Date(t2), "d-M-y HH:mm:ss");
      let score = 0;
      if (diff > 3600) score = 20;
      this.t5_stat.tripout.score = score;

      console.log(
        "to_check diff tripout: " + diff + ",start:" + str1 + ",end:" + str2
      );
      return diff;
    },

    t5_to_report_bank2() {
      let t1 = this.t5_stat.bank2.t[0];
      let n = this.t5_stat.bank2.t.length;
      let t2 = this.t5_stat.bank2.t[n - 1];
      let diff = DiffSeconds(t1, t2);
      let str1 = getDateString(new Date(t1), "d-M-y HH:mm:ss");
      let str2 = getDateString(new Date(t2), "d-M-y HH:mm:ss");
      let score = 0;
      if (diff > 600) score = 20;
      this.t5_stat.bank2.score = score;

      console.log(
        "to_check diff bank2: " + diff + ",start:" + str1 + ",end:" + str2
      );
      return diff;
    },
    t5_to_report_total_score() {
      let score =
        this.t5_stat.bank.score +
        this.t5_stat.tripin.score +
        this.t5_stat.downhole.score +
        this.t5_stat.tripout.score +
        this.t5_stat.bank2.score;
      console.log("to_check total score: " + score);
      return score;
    },
    t5_to_report_downholetime() {
      let n = this.t5_stat.bank.t.length;
      if (n == 0) return 0;
      let t1 = this.t5_stat.bank.t[n - 1];
      let n2 = this.t5_stat.bank2.t.length;
      if (n2 == 0) return 0;
      let t2 = this.t5_stat.bank2.t[0];
      let diff = DiffSeconds(t1, t2);

      let str1 = getDateString(new Date(t1), "d-M-y HH:mm:ss");
      let str2 = getDateString(new Date(t2), "d-M-y HH:mm:ss");
      console.log(
        "to_check diff downholetime: " +
          diff +
          ",start:" +
          str1 +
          ",end:" +
          str2
      );
      let res = { start: str1, stop: str2, diff: diff };
      return res;
    },

    _error() {
      console.log("to_error");
    },
    task_mtot() {
      var that = this;
      return new Promise((resolve) => {
        if (that.node != "UMPU" || that.lines.length == 0) {
          resolve();
        }
        const blocs = umpu_blocs;
        let table_meas = [];
        const start_time_rtc = new RTC(that.start_time);
        const stop_time_rtc = new RTC(that.stop_time);

        var bloc_parser = new BlocParser(blocs);

        for (var i = 0; i < that.lines.length; i++) {
          var line = that.lines[i];
          var cells = line.split(",");
          var sheetname = cells[0].trim(" ");
          var table_id = bloc_parser.getTableId(sheetname);

          if (table_id == 21 || table_id == 14) {
            var table_item = {
              sheetname: sheetname,
              nr: i,
              data: line,
              cells: cells,
            };

            table_meas.push(table_item);
          }
        }
        //const STATE_ERROR = "error";
        const STATE_IDLE = "idle";
        const STATE_CASING = "casing";
        const STATE_NOTCASING = "notcasing";
        let state = STATE_IDLE;
        let prev_state = state;
        var mFSM = new FSMEx(that, "idle", mtotal_transitions);
        let shouldSkip = false;
        table_meas.forEach((row) => {
          if (shouldSkip) return;
          const timestr = row.cells[1];
          var rtc = new RTC(row.cells[1]);
          if (rtc.t < start_time_rtc.t) return;
          if (rtc.t > stop_time_rtc.t) {
            shouldSkip = true;
            return;
          }
          let mtot = parseFloat(row.cells[13]);
          mFSM.context.t6_data = {
            t: rtc.t,
            mtotal: mtot,
          };

          if (prev_state == STATE_IDLE) {
            console.log(prev_state, timestr);
            mFSM.transition("init");

            mFSM.context.t6_stat.mtots.push(mtot);
            mFSM.context.t6_stat.ts.push(rtc.t);
            mFSM.context.t6_stat.mtots.push(mtot);
            mFSM.context.t6_stat.ts.push(rtc.t);
            //mFSM.context.t6_stat.mtots.push(mtot);
            //mFSM.context.t6_stat.ts.push(rtc.t);
          } else {
            if (mFSM.context.t6_stat.mtots.length < 2) {
              mFSM.context.t6_stat.mtots.push(mtot);
              mFSM.context.t6_stat.ts.push(rtc.t);
            } else {
              const mtots = mFSM.context.t6_stat.mtots;
              let max = Math.max(mtots[0], mtots[1]); //, mtots[2]);
              let min = Math.min(mtots[0], mtots[1]); //, mtots[2]);
              let spread = ((max - min) * 100000).toFixed(1);
              const CASING_SPREAD_IN_NT = 3000;
              const OUTCASING_SPREAD_IN_NT = 600;
              if (spread > CASING_SPREAD_IN_NT) {
                if (prev_state == STATE_NOTCASING) {
                  mFSM.transition("switch");
                }
              } else if (spread < OUTCASING_SPREAD_IN_NT) {
                if (prev_state == STATE_CASING) {
                  mFSM.transition("switch");
                }
              }

              mFSM.context.t6_stat.mtots.shift();
              mFSM.context.t6_stat.mtots.push(mtot);

              mFSM.context.t6_stat.ts.shift();
              mFSM.context.t6_stat.ts.push(rtc.t);
            }
          }
          state = mFSM.state;
          prev_state = state;
        });
        //at the end merge casing if the two is too close (say less then 3600 1hr)
        let results = this.t6_stat.res;
        let tmp = [];
        let ref_time_t = null;
        results.forEach((res) => {
          if (ref_time_t == null) {
            tmp.push(res);
            ref_time_t = res.end;
          } else {
            let diff = DiffSeconds(ref_time_t, res.start);
            console.log("diff", diff);
            if (diff > 3600) {
              tmp.push(res);
            } else {
              let n = tmp.length;
              tmp[n - 1].end = res.end;
            }
            ref_time_t = res.end;
          }
        });
        let score = 0;
        if (tmp.length > 0) score = 1;
        setTimeout(() => {
          let o = {
            casings: tmp,
            score: score,
          };

          resolve(o);
        }, 0);
      });
    },
    _task_mtot_init() {
      let time_str = getDateString(new Date(this.t6_data.t), "d-M-y HH:mm:ss");
      this.t6_stat.res = [];
      console.log("t6: init", time_str);
    },
    _task_mtot_switch_to_casing() {
      let time_str = getDateString(new Date(this.t6_data.t), "d-M-y HH:mm:ss");
      let casing = {
        start: this.t6_data.t,
        end: null,
        diff: null,
      };
      this.t6_stat.res.push(casing);
      console.log("t6: to casing", time_str);
    },
    _task_mtot_switch_to_notcasing() {
      let time_str = getDateString(new Date(this.t6_data.t), "d-M-y HH:mm:ss");

      let n = this.t6_stat.res.length;
      if (n > 0) {
        this.t6_stat.res[n - 1].end = this.t6_data.t;
        this.t6_stat.res[n - 1].diff = DiffSeconds(
          this.t6_stat.res[n - 1].start,
          this.t6_stat.res[n - 1].end
        );
      }
      console.log("t6: out of casing", time_str);
    },

    task_inc() {
      var that = this;
      that.msg = "t5: processing downhole hours from inc...";
      return new Promise((resolve, reject) => {
        console.log(
          "t5 calculate downhole hours (inc): start",
          JSON.parse(JSON.stringify(new Date()))
        );
        if (that.lines.length == 0) {
          reject();
          return;
        }
        let table_meas = [];
        const blocs = umpu_blocs;
        const start_time_rtc = new RTC(that.start_time);
        const stop_time_rtc = new RTC(that.stop_time);

        var bloc_parser = new BlocParser(blocs);
        for (var i = 0; i < that.lines.length; i++) {
          var line = that.lines[i];
          var cells = line.split(",");
          var sheetname = cells[0].trim(" ");
          var table_id = bloc_parser.getTableId(sheetname);

          if (table_id == 21 || table_id == 14) {
            var table_item = {
              sheetname: sheetname,
              nr: i,
              data: line,
              cells: cells,
            };

            table_meas.push(table_item);
          }
        }
        const STATE_TRIPIN = "tripin";
        const STATE_BANK = "bank";
        const STATE_BANK2 = "bank2";
        const STATE_TRIPOUT = "tripout";
        const STATE_DOWNHOLE = "downhole";
        const STATE_IDLE = "idle";
        const STATE_ERROR = "error";

        let state = STATE_IDLE;
        let prev_state = STATE_IDLE;
        let total_on = 0;
        let total_off = 0;
        console.log("t5 table length:" + table_meas.length);
        //let prev_time_rtc;
        var mFSM = new FSMEx(that, "idle", inc_transitions);
        for (var meas_row = 0; meas_row < table_meas.length; meas_row++) {
          const timestr = table_meas[meas_row].cells[1];
          var rtc = new RTC(table_meas[meas_row].cells[1]);
          if (rtc.t < start_time_rtc.t) continue;
          if (rtc.t > stop_time_rtc.t) break;
          let inc = parseInt(table_meas[meas_row].cells[11]);
          let gtot = parseInt(table_meas[meas_row].cells[12]);
          let mtot = parseInt(table_meas[meas_row].cells[13]);
          mFSM.context.t5_data = {
            t: rtc.t,
            inc: inc,
            gtot: gtot,
            mtot: mtot,
            state: mFSM.state,
          };
          if (prev_state == STATE_IDLE && inc > 80) {
            mFSM.transition("bank");
          } else if (prev_state == STATE_BANK && inc > 80) {
            console.log("bank stay", inc, timestr);
            mFSM.transition("stay");
          } else if (prev_state == STATE_BANK && inc < 10) {
            console.log("bank to tripin", inc, timestr);
            mFSM.transition("tripin");
          } else if (prev_state == STATE_TRIPIN && inc < 10) {
            console.log("tripin stay", inc, timestr);
            mFSM.transition("stay");
          } else if (prev_state == STATE_TRIPIN && inc > 10) {
            console.log("tripin to downhole", inc, timestr);
            mFSM.transition("downhole");
          } else if (prev_state == STATE_DOWNHOLE && inc > 10) {
            console.log("downhole stay", inc, timestr);
            mFSM.transition("stay");
          } else if (prev_state == STATE_DOWNHOLE && inc < 10) {
            console.log("downhole to tripout", inc, timestr);
            mFSM.transition("tripout");
          } else if (prev_state == STATE_TRIPOUT && inc < 10) {
            console.log("tripout stay", inc, timestr);
            mFSM.transition("stay");
          } else if (prev_state == STATE_TRIPOUT && inc > 80) {
            console.log("tripout to bank2", inc, timestr);
            mFSM.transition("bank2");
          } else if (prev_state == STATE_BANK2 && inc > 80) {
            console.log("bank2 stay", inc, timestr);
            mFSM.transition("stay");
          } else if (prev_state == STATE_BANK && inc > 10) {
            console.log("do nothing here (bank) inc > 10", timestr);
          } else if (prev_state == STATE_TRIPIN && inc == 10) {
            console.log("do nothing here (tripin) inc == 10", timestr);
          } else if (prev_state == STATE_TRIPOUT && inc >= 10 && inc < 80) {
            console.log("do nothing here (tripout) inc (10,80)", timestr);
          } else {
            console.log(
              "t5 rejecting... state:" +
                prev_state +
                " inc: " +
                inc +
                " " +
                table_meas[meas_row].nr,
              timestr
            );
            mFSM.transition("error");
          }
          state = mFSM.state;

          //prev_time_rtc = rtc;
          prev_state = state;
          if (state == STATE_TRIPIN) {
            let tripin_secs = that.t5_to_report_tripin();
            if (tripin_secs > 7200 && inc > 10) {
              console.log("trip in do downhole (timeout long enough)", inc);
              mFSM.transition("downhole");
            }
          }
          if (state == STATE_ERROR) {
            break;
          }
        }

        let total_diff = DiffSeconds(start_time_rtc.t, stop_time_rtc.t);
        console.log(
          "!!!! total_off:" +
            total_off +
            ",total_on:" +
            total_on +
            ",total:" +
            total_diff
        );
        that.t5_to_report_bank();
        that.t5_to_report_tripin(true);
        that.t5_to_report_downhole();
        that.t5_to_report_tripout();
        that.t5_to_report_bank2();
        let score = that.t5_to_report_total_score();
        let downholetime = that.t5_to_report_downholetime();
        let n_hours = secs2jobhours(downholetime.diff);
        var info_downholehours = {
          name: "Downhole Hours",
          value: n_hours,
          enable: true,
          option: "inc",
          score: score,
          start: downholetime.start,
          stop: downholetime.stop,
        };

        resolve(info_downholehours);
      });
    },

    processCardsTask() {
      var that = this;
      return new Promise((resolve) => {
        that.msg = "process cards...";
        var start_time_rtc = new RTC(that.start_time);
        var stop_time_rtc = new RTC(that.stop_time);

        var blocs = umpu_blocs;
        if (that.node == "UDRX") {
          blocs = udrx_blocs;
        }
        if (that.node == "UGTX") {
          blocs = ugtx_blocs;
        }
        if (that.node == "UPDR") {
          blocs = updr_blocs;
        }
        if (that.node == "UFGI") {
          blocs = ufgi_blocs;
        }
        if (that.node == "GDAI") {
          blocs = gdai_blocs;
        }
        if (that.node == "UHPM") {
          blocs = uhpm_blocs;
        }

        if (that.node == "UETX") {
          blocs = uetx_blocs;
        }

        var bloc_parser = new BlocParser(blocs);

        console.log("t2 process cards: promise, length:" + that.lines.length);

        if (that.lines.length == 0) {
          resolve();
        }
        let dd = [];
        var ind = 0;
        let lnum;
        for (lnum = 0; lnum < that.lines.length; lnum++) {
          const line = that.lines[lnum];
          var cells = line.split(",");
          var sheetname = cells[0].trim(" ");
          if (sheetname.match(/Diag/g) == null && cells[1] != undefined) {
            var rtc = new RTC(cells[1]);
            if (rtc.t < start_time_rtc.t) continue;
            if (rtc.t > stop_time_rtc.t) break;
          }

          var table_id = bloc_parser.getTableId(sheetname);

          if (table_id > 0) {
            let objs = dd.filter(function (elem) {
              if (elem.id == table_id) return true;
              else return false;
            });
            if (objs.length == 0) {
              dd.push({
                id: table_id,
                name: sheetname,
                value: [],
              });
            }
          }

          var table_item = {
            sheetname: sheetname,
            nr: ind,
            data: line,
            cells: cells,
          };
          if (table_id > 0) {
            if (dd.length == 0) {
              dd.push({
                id: table_id,
                name: sheetname,
                value: [],
              });
            }
            let objsById = dd.filter(function (elem) {
              if (elem.id == table_id) return true;
              else return false;
            });
            objsById[0].value.push(table_item);
          }

          ind++;
        }

        console.log("t2 tables: " + dd.length);
        console.log("t2 cards: " + that.cards.length);

        let len = that.cards.length;
        for (var i = 0; i < len; i++) {
          var card = that.cards[i];
          var filter = card.filter;
          let has_filter = false;
          if (filter != undefined && filter != "") {
            console.log("t2 filter:" + filter);
            has_filter = true;
          }
          const tableId = parseInt(card.tableId);
          if (tableId != undefined && tableId > 0) {
            let tmp_tables = dd.filter(function (elem) {
              if (elem.id == tableId) return true;
              return false;
            });
            if (tmp_tables.length > 0) {
              if (has_filter) {
                const regex = new RegExp(filter, "i");
                const result = tmp_tables[0].value.filter((el) => {
                  const res = el.data.match(regex);
                  //console.log(res, el.data);
                  return res;
                });
                if (result.length > 0) {
                  that.setCardRows(card.name.toLowerCase(), result);
                  that.showCard(card.name.toLowerCase(), true);
                }
              } else {
                that.setCardRows(card.name.toLowerCase(), tmp_tables[0].value);
                that.showCard(card.name.toLowerCase(), true);
              }
            }
          }
        }

        that.showCard("info", true);
        if (that.node == "UHPM") {
          that.showCard("tool", true);
        }

        if (that.node == "UMPU") {
          that.showCard("tool", true);
          that.showCard("firmware", true);
        }
        if (that.node == "UGTX") {
          that.showCard("tool", true);
        }
        setTimeout(() => {
          resolve(that.cards);
        }, 100);
      });
    },
    updateCurrentColumns(columns) {
      console.log("tracker: current columns length," + columns.length);
      for (var i = 0; i < columns.length; i++) {
        if (this.verbose)
          console.log("tracker: " + columns[i].id + ":" + columns[i].name);
      }
      this.currentColumns = columns;
    },
    changemaxCols(maxcols) {
      this.maxcols = maxcols;
    },
    updateptd(row) {
      var cols = this.currentColumns;
      if (cols == null || cols.cells == null) return;
      this.ptd.items = [];
      var len = cols.length;
      if (len < row.cells.length) len = row.cells.length;

      for (var i = 0; i < len; i++) {
        var val = row.cells[i];
        var name = cols[i];
        if (
          name != undefined &&
          Object.prototype.hasOwnProperty.call(name, "name")
        ) {
          name = name["name"];
        }
        var enable = true;
        if (
          name == "TableName" ||
          name == "RTC" ||
          (name != undefined && name.search("_shock") != -1) ||
          (name != undefined && name.search("_service") != -1) ||
          (name != undefined && name.search("_calib") != -1) ||
          (name != undefined && name.search("_3rd_prod") != -1)
        )
          enable = false;
        var item = { name: name, value: val, enable: enable };
        this.ptd.items.push(item);
      }
    },
    do_toggle(act) {
      var arr = act.split(" ");
      if (arr.length > 1) {
        var op = arr[0];
        var target = arr[1].toLowerCase();
        if (target == "card") {
          target = arr[2].toLowerCase();
        }
        if (op == "verbose") {
          if (target == "on") {
            this.verbose = true;
          }
          if (target == "off") {
            this.verbose = false;
          }
        }
        if (op == "hide") {
          for (var i = 0; i < this.cards.length; i++) {
            if (this.cards[i].name.toLowerCase() == target) {
              this.cards[i].show_card = false;
            }
          }
        }
        if (op == "show") {
          for (i = 0; i < this.cards.length; i++) {
            if (this.cards[i].name.toLowerCase() == target) {
              this.cards[i].show_card = true;
            }
          }
        }
        if (op == "toggle") {
          for (i = 0; i < this.cards.length; i++) {
            if (this.cards[i].name.toLowerCase() == target) {
              this.cards[i].show_card = !this.cards[i].show_card;
            }
          }
        }
      }
    },

    updateCard(bloc_parser, cardname, cells) {
      var sheetname = cells[0];
      if (sheetname.toLowerCase().includes("info")) {
        console.log("check card: " + cardname, sheetname);
      }
      for (var i = 0; i < this.cards.length; i++) {
        if (this.cards[i].name.toLowerCase() == cardname) {
          for (var j = 0; j < this.cards[i].items.length; j++) {
            var tableId = parseInt(this.cards[i].items[j].tableId);
            var givenId = bloc_parser.getTableId(sheetname);
            if (givenId != tableId) continue;

            var tableCol = parseInt(this.cards[i].items[j].tableCol);
            var tableMatch = this.cards[i].items[j].tableMatch;
            //var colname = bloc_parser.getTableDataCol(tableId, tableCol - 2);

            this.cards[i].items[j].value = cells[tableCol];
            this.cards[i].items[j].enable = true;
            if (this.cards[i].items[j].value == "") {
              this.cards[i].items[j].enable = false;
            } else {
              if (tableMatch != undefined && tableMatch != null) {
                const myRe = new RegExp(tableMatch, "g");
                const match_res = this.cards[i].items[j].value.match(myRe);
                if (match_res == null) {
                  this.cards[i].items[j].enable = false;
                }
                console.log(
                  this.cards[i].items[j].name,
                  this.cards[i].items[j].value
                );
                console.log(
                  "match",
                  tableMatch,
                  sheetname,
                  cardname,
                  match_res
                );
              }
            }
            console.log("update card: " + cardname, sheetname);
          }
          break;
        }
      }
    },
    updateCardItem(cardname, itemname, itemval) {
      if (itemval == undefined) return;

      for (var i = 0; i < this.cards.length; i++) {
        if (this.cards[i].name.toLowerCase() == cardname) {
          for (var j = 0; j < this.cards[i].items.length; j++) {
            if (this.cards[i].items[j].name.toLowerCase() == itemname) {
              this.cards[i].items[j].value = itemval.value;
              this.cards[i].items[j].enable = true;
              return;
            }
          }
          break;
        }
      }
    },
    findCardOfItem(cardname, item) {
      let res = [];
      for (var i = 0; i < this.cards.length; i++) {
        if (this.cards[i].name.toLowerCase() == cardname.toLowerCase()) {
          res = this.cards[i].items;
          break;
        }
      }
      let found = false;
      for (i = 0; i < res.length; i++) {
        if (res[i].name == item.name) {
          found = true;
          break;
        }
      }
      return found;
    },

    getCardItems(cardname) {
      let res = [];
      for (var i = 0; i < this.cards.length; i++) {
        if (this.cards[i].name.toLowerCase() == cardname.toLowerCase()) {
          res = this.cards[i].items;
          break;
        }
      }
      return res;
    },
    setCardItems(cardname, items) {
      for (var i = 0; i < this.cards.length; i++) {
        if (this.cards[i].name.toLowerCase() == cardname.toLowerCase()) {
          this.cards[i].items = items;
          break;
        }
      }
    },

    showCard(cardname, show_or_hide) {
      for (var i = 0; i < this.cards.length; i++) {
        if (this.cards[i].name.toLowerCase() == cardname) {
          this.cards[i].show_card = show_or_hide;
          break;
        }
      }
    },

    setCardRows(cardname, rows) {
      for (var i = 0; i < this.cards.length; i++) {
        if (this.cards[i].name.toLowerCase() == cardname) {
          this.cards[i].rows = rows;
          break;
        }
      }
    },
    setCardRowsErrorBits(cardname, rows) {
      console.log("error bits");
      for (var i = 0; i < this.cards.length; i++) {
        if (this.cards[i].name.toLowerCase() == cardname) {
          this.cards[i].rows = rows;

          break;
        }
      }
    },

    onChangeSheet: function (e) {
      console.log("onChangeSheet: " + e);
      console.log(this.currentSheet);
      this.filterTable();
    },
  },
  data: function () {
    return {
      task_psi_result: null,
      task_mtot_result: null,
      completion: 0,
      width: 0,
      delay: 100,
      interval: null,
      jobAnalyzing: false,
      jobIsDone: false,
      job: {
        node: "",
        data: "",
        score: 0,
        size: null,
        completion: 0,
        runs: [],
      },
      t6_data: null,
      t6_stat: {
        ts: [],
        mtots: [],
        res: [],
      },
      t4_data: null,
      t4_stat: {
        on: {
          t: [],
        },
        off: {
          t: [],
        },
        res: [],
      },
      t5_data: null,
      t5_stat: {
        bank: {
          t: [],
          inc: [],
          gtot: [],
          mtot: [],
          score: 0,
        },
        tripin: {
          t: [],
          inc: [],
          gtot: [],
          mtot: [],

          score: 0,
        },
        downhole: {
          t: [],
          inc: [],
          gtot: [],
          mtot: [],

          score: 0,
        },
        tripout: {
          t: [],
          inc: [],
          gtot: [],
          mtot: [],

          score: 0,
        },
        bank2: {
          t: [],
          inc: [],
          gtot: [],
          mtot: [],

          score: 0,
        },
      },
      currentPage: 1,
      perPage: 25,
      node: "",
      msg: "",
      loading: false,
      cards: [],
      mtotal_transitions: mtotal_transitions,
      inc_transitions: inc_transitions,
      psi_transitions: psi_transitions,
      umpu: umpu_blocs,
      lines: [],
      g: Group,
      x_sel: 1,
      y_sel: [2],
      y_names: [],
      start_nr: 0,
      stop_nr: 0,
      start_time: BEGINTIMESTR, // start time of job to plot, can be controlled by user
      stop_time: BEGINTIMESTR, // stop time of job to plot
      begin_time: "", // begin time of job (from csv)
      end_time: "", // end time of job  (from csv)
      show_slider: false,
      show_data: false,
      show_jobpanel: true,
      show_cards: true,
      show_groups: false,
      data: [
        {
          x: [
            "23-Sep-2021 14:48:02",
            "23-Sep-2021 14:53:02",
            "23-Sep-2021 14:58:02",
            "23-Sep-2021 15:03:02",
          ],
          y: [10, 15, 13, 17],
          type: "scatter",
        },
      ],
      layout: {
        xaxis: {
          tickmode: "linear", //  If "linear", the placement of the ticks is determined by a starting position `tick0` and a tick step `dtick`
          tick0: "23-Sep-2021 14:48:02",
          dtick: 1000, // milliseconds
        },
        title: "My graph",
      },
      maxcols: 0,
      show_sheetname: false,
      show_nr: false,
      currentRows: [],
      rows_length: 0,
      currentSheet: "",
      filter: "",
      debouncedFilter: "",
      currentColumns: [],
      sheetnames: [],
      csv: null,
      fileinput: "",
      progress_val: 0,
    };
  },
};
</script>

<style scoped>
h4 {
  padding: 2px;
  border-bottom: 0px solid #aaaaaa;
}

label {
  margin-bottom: 0.5rem;
}
.tracker .block {
  color: #e3e3e4;
}
.tracker .block h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-size: 45px;
  line-height: 60px;
  letter-spacing: 10px;
  padding-bottom: 45px;
}
.tracker .block p {
  font-size: 23px;
  line-height: 40px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  letter-spacing: 3px;
}
.tracker table {
  border-radius: 5px;
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  word-wrap: break-word;
}
.table-condensed {
  font-size: 10px;
}

.table-nonfluid {
  width: auto !important;
}
.tracker table.table-fit {
  width: auto !important;
  table-layout: auto !important;
}
.tracker table.table-fit thead tr th {
  width: auto !important;
}

.tracker table.table-fit thead tr th,
table.table-fit tfoot tr th {
  width: auto !important;
}
.tracker table.table-fit tbody td,
table.table-fit tfoot td {
  width: auto !important;
}

.progress-bar {
  display: inline-block;
  align-content: stretch;
  width: 0;
  line-height: 20px;
}
.table-wrap {
  width: 100%;
  overflow-x: auto;
}

.side-panel {
  min-width: 100px;
}

table {
  font-family: "Roboto", arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-size: 10px;
}

th {
  background-color: #dddddd;
}

.bd {
  padding: 1.5rem;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 0.5rem;
  border-width: 0.2rem;
  border: solid #f9f9f7;
  font-family: "Roboto", arial, sans-serif;
}

.form-horizontal .control-label {
  text-align: right;
  font-family: "Roboto";
}

#tracker-progress {
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
}

.shell {
  height: 20px;
  width: 250px;
  border: 1px solid #aaa;
  border-radius: 13px;
  padding: 3px;
  box-sizing: content-box !important;
}

#tracker-progress button {
  margin: 10px;
  border: 1px solid #aaa;
  background: none;
  border-radius: 50%;
  padding: 5px 8px;
  outline: none;
  cursor: pointer;
}

.bar {
  width: 100%;
  height: 3px;
  color: white;
  border-radius: 5px;
  z-index: 100;
  top: 0px;
  left: 0;
  width: 100%;
  height: 2px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
  transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  position: absolute;
}

.fixed {
  position: fixed;
}

.bar > .progress {
  position: absolute;
  display: inline-block;
  background: red;
  height: 100%;
  box-shadow: 0 0 10px red, 0 0 5px red;
  width: 0px;
  transition: all 200ms ease;
  -webkit-transition: all 200ms ease;
}

.bar > .title {
  text-align: right;
  position: absolute;
  right: 0px;
  top: 2px;
  color: red;
}

.bar.visible,
.popup.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 2s linear;
}

.tracker .container-fluid .alert-info > .button {
  background-color: red;
}

.alert-dismissible .alert-info > * {
  background-color: red;
  color: #f9f9f7;
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  opacity: 0.75;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.loader-wrapper {
  position: relative;
  height: 20px;
  background-color: gray;
  width: 100%;
  text-align: center;
  color: #fff;
}
.loader {
  position: absolute;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
