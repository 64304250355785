<template>
  <div>
    <b-row>
      <b-col>
        <h4>Import CSV</h4>
        <div class="px-3 py-2">
          <p>
            Here is the place to import job logging files. ULogger file format
            usually starts with "RawData-" and come with 4 charactor node name.
            You can import csv from different nodes, and multiple select all the
            files if the job contains multiple logging CSVs. Use `Filter` to
            display the node you want to analyze.
          </p>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <input
          ref="fileinput"
          class="form-control mb-3"
          accept=".csv"
          type="file"
          id="file-input"
          @change="onFileChange"
          multiple
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="auto">
        <label for="cureentNode" class="control-label">Node</label>
      </b-col>
      <b-col>
        <v-select
          :options="nodes"
          v-model="currentNode"
          @input="onChangeNode"
        ></v-select>
      </b-col>
      <b-col md="auto">
        <b-button
          :disabled="!isActive"
          variant="primary"
          v-show="rows.length > 0 && currentNode != ''"
          type="submit"
          @click="setNode"
        >
          Set
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div v-show="alertMsg != ''" class="alert alert-primary">
          {{ alertMsg }}
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h4>Job Container</h4>
      </b-col>
    </b-row>

    <b-row>
      <b-table
        small
        id="my-table"
        show-empty
        striped
        hover
        :items="rows"
        :fields="fields"
      ></b-table>
    </b-row>
    <b-row>
      <b-col>
        <b-button
          variant="primary"
          v-show="true || rows.length > 0"
          @click="clear"
          style="margin-right: 3px"
          >Clear</b-button
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
import mylib from "@/mylib";
import vSelect from "vue-select";
const debounce = mylib.debounce;
const pFileReader = mylib.pFileReader;

export default {
  name: "AJob",
  components: {
    vSelect,
  },
  props: {},
  created: function () {
    this.node = "N/A";
    this.fileinput = "";
  },
  computed: {
    nodes() {
      let res_nodes = [];
      this.content.forEach((row) => {
        if (
          res_nodes.filter(
            (name) => name.toLowerCase() == row.name.toLowerCase()
          ).length == 0
        ) {
          res_nodes.push(row.name);
        }
      });
      return res_nodes;
    },
    rows() {
      console.log("AJob: rows", this.content.length);
      return this.makeRows();
    },
    fields() {
      let fields = [];
      let rec1 = {
        key: 1,
        label: "name",
      };
      let rec2 = {
        key: 2,
        label: "address",
      };

      fields.push(rec1);
      fields.push(rec2);
      return fields;
    },
  },
  data: function () {
    return {
      filter: "",
      content: [],
      rows_len: 0,
      files: [],
      currentNode: "",
      alertMsg: "",
      isActive: true,
    };
  },
  watch: {
    filter: debounce(function (filt) {
      console.log("content: update" + filt);
      if (!this.node.includes(filt)) {
        let rows = this.makeRows();
        if (rows.length > 0) this.node = rows[0].name;
      }
      this.$forceUpdate();
    }, 500),
    content: function (thatContent) {
      console.log("content: update", thatContent);
      this.$forceUpdate();
    },
  },
  methods: {
    onChangeNode(v) {
      this.isActive = true;
      this.alertMsg = "";
      console.log("content: update", v);
    },
    makeRows() {
      let rows = [];

      this.content.forEach((row) => {
        if (
          this.filter == "" ||
          row.name.toLowerCase().includes(this.filter.toLowerCase())
        )
          rows.push({
            name: row.name,
            addresss: row.address,
          });
      });
      return rows;
    },
    formatNames(files) {
      if (files.length == 1) {
        let fa = files[0].name;
        const rx2 = /RawData-[UG][a-zA-Z][a-zA-Z][a-zA-Z][0-9]+-([0-9]+)-.*/g;
        let res2 = rx2.exec(fa);
        let addr_fa = res2[1];
        return addr_fa;
      }
      return `${files.length} files selected`;
    },
    clear() {
      this.alertMsg = "";
      this.currentNode = "";
      this.content.splice(0, this.content.length);
      this.files = [];

      const input = this.$refs.fileinput;
      input.type = "text";
      input.type = "file";
    },
    setNode() {
      let data = "";
      let node = this.currentNode;
      if (node == "") {
        alert("please select node.");
        return;
      }

      console.log("set Node", node);
      this.content.forEach((item) => {
        if (item.name.toLowerCase().includes(node.toLowerCase())) {
          data = data + item.lines;
        }
      });
      this.node = node;
      //this.isActive = false;
      this.alertMsg = "OK";
      this.$emit("select-node", {
        node: node,
        data: data,
        size: data.length,
        runs: [],
        completion: 0,
        score: 0,
      });
    },
    keydown: function (event) {
      console.log("what" + event);
      event.stopImmediatePropagation();
    },
    onFileChange(e) {
      var that = this;
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      this.alertMsg = "";
      let container = [];
      files.forEach((file) => {
        console.log(file);
        if (file.name.endsWith(".csv")) container.push(file);
      });
      container.sort((a, b) => {
        let fa = a.name;
        let fb = b.name;
        const rx2 = /RawData-[UG][a-zA-Z][a-zA-Z][a-zA-Z][0-9]+-([0-9]+)-.*/g;
        let res2 = rx2.exec(fa);
        let addr_fa = res2[1];

        const rx2b =
          /RawData-[UG][a-zA-Z][a-zA-Z][a-zA-Z][0-9]+-([0-9]+-[0-9a-f]+)-.*/g;
        let res2_fb = rx2b.exec(fb);
        let addr_fb = res2_fb[1];

        if (addr_fa < addr_fb) return -1;
        return 1;
      });

      Promise.all(Array.prototype.map.call(container, pFileReader))
        .then((urls) => {
          let ind = 0;
          urls.forEach((url) => {
            const rx = /RawData-([UG][a-zA-Z][a-zA-Z][a-zA-Z]).*/g;
            let res = rx.exec(container[ind].name);
            let node;
            if (res.length > 1) {
              node = res[1];
            }
            const rx2 =
              /RawData-[UG][a-zA-Z][a-zA-Z][a-zA-Z][0-9]+-([0-9]+)-.*/g;
            let res2 = rx2.exec(container[ind].name);
            let addr = res2[1];

            console.log("!! promise:" + node + addr);
            that.content.push({ name: node, address: addr, lines: url });
            if (ind == 0) {
              that.node = node;
            }
            ind++;
          });
        })
        .catch((error) => {
          // ...handle/report error...
          console.log(error);
        });
    },
  },
};
</script>

<style>
/*
#formFile::before {
  content: "Import CSV(s)";
  position: absolute;
  z-index: 2;
  display: block;
  background-color: #eee;
  
}*/
.b-icon.bi {
  display: inline-block;
  overflow: visible;
  vertical-align: -0.15em;
}
.b-sidebar.b-sidebar-right > .b-sidebar-header .close {
  margin-right: auto;
  background-color: #eee;
  border: #eee;
}
</style>
