import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import Home from '../views/Home.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import firebase from "firebase/compat/app";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            authRequired: false,
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            authRequired: false,
        },

    },
    {
        path: '/forgotpassword',
        name: 'ForgotPassword',
        component: ForgotPassword,
        meta: {
            authRequired: false,
        },

    },
    {
        path: '/profile',
        name: 'Profile',
        component: () =>
            import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
        meta: {
                authRequired: false,
        },
    
    },

    {
        path: '/register',
        name: 'Register',
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/About.vue'),
        meta: {
            authRequired: false,
        },
    
    },
    {
        path: '/tracker',
        name: 'Tracker',
        component: () =>
            import(
                /* webpackChunkName: "dashboard" */ '../views/Tracker.vue'
            ),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/rd',
        name: 'RD',
        component: () =>
            import(
                /* webpackChunkName: "dashboard" */ '../views/RD.vue'
            ),
        meta: {
            authRequired: true,
        },
    },

];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    firebase.getCurrentUser().then(() => {
    if (to.matched.some(record => record.meta.authRequired)) {
        if (firebase.auth().currentUser) {            
            next();
        } else {
            alert('You must be logged in to see this page');
            next({
                path: '/login',
            });
        }
    } else {
        next();
    }
   })
});

export default router;