<template>
  <div class="blocs" v-show="show_blocs">
    <div v-for="bloc in Blocs.blocs" :key="bloc['-id']">
      <div v-for="(item, index) in bloc" :key="index">
        <div v-if="item['-name'] == currentSheet">
          <span v-for="(data, index) in item['Data']['d']" :key="index">
            {{ data["-name"] }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import umpu from "../json/blocs/umpu.json";
import udrx from "../json/blocs/udrx.json";
import ugtx from "../json/blocs/ugtx.json";
import ufgi_blocs from "../json/blocs/ufgi.json";
import uhpm_blocs from "../json/blocs/uhpm.json";

export default {
  name: "Blocs",
  props: {
    show_blocs: {
      type: Boolean,
    },
    currentSheet: {
      type: String,
    },
    node: {
      type: String,
      default: function() {
        return "UMPU";
      }
    }
  },
  created: function () {
    let blocs = umpu;
    if(this.node == "UDRX") 
      blocs = udrx;
    if(this.node == "UGTX") 
      blocs = ugtx;
    if(this.node == "UFGI") 
      blocs = ufgi_blocs;
    if(this.node == "UHPM") 
      blocs = uhpm_blocs;

    this.Blocs = blocs;
    this.currentColumns = [];
  },
  watch: {
    node: function(newNode){
      let blocs = umpu;
      if(newNode == "UDRX") 
        blocs = udrx;
      if(newNode == "UGTX") 
        blocs = ugtx;
      if(newNode == "UFGI") 
        blocs = ufgi_blocs;
      if(newNode == "UHPM") 
        blocs = uhpm_blocs;

      this.Blocs = blocs;

    },
    show_blocs: function () {},
    currentColumns: function () {
      console.log("emit currentColumns");
      this.$emit("update-current-columns", this.currentColumns);
    },
    currentSheet: function () {
      console.log("watch currentSheet emit currentColumns");
      this.update_columns();
      this.$emit("update-current-columns", this.currentColumns);
    },
  },
  methods: {
    update_columns: function () {
      this.currentColumns = [];
      let arr = this.Blocs["blocs"]["bloc"];
      var col = null;
      var IND = 0;
      for (var i = 0; i < arr.length; i++) {
        var tab = arr[i];
        if (
          tab["-name"] == this.currentSheet ||
          tab["-othername"] == this.currentSheet ||
          tab["-title"] == this.currentSheet
        ) {
          var hdr = tab["Hdr"];
          if(hdr != null) {
            col = {
               id: IND++,
               name: "TableName",
            };
            this.currentColumns.push(col);


            col = {
               id: IND++,
               name: "RTC",
            };
            this.currentColumns.push(col);
          }
          var datas = tab["Data"]["d"];
          var bitstr = datas["-bits"];
          if (bitstr != undefined && bitstr != "") {
            var bits = bitstr.split(",");
            if (bits != undefined && bits.length > 0) {
              for (var bind = 0; bind < bits.length; bind++) {
                col = {
                  id: IND++,
                  name: bits[bind].trim(),
                };
                this.currentColumns.push(col);
              }
            }
          }
          for (var j = 0; j < datas.length; j++) {
            if (datas[j]["-desc"] != undefined && datas[j]["-desc"] != "") {
              col = {
                id: IND++,
                name: datas[j]["-desc"],
              };
              this.currentColumns.push(col);
            } else {
              var subname = datas[j]["-name"]
              var sub_datas = datas[j]["d"];
              if(sub_datas != null && sub_datas.length > 1) {
                for(var subj=0; subj< sub_datas.length; subj++) {
                  col = {
                      id: IND++,
                      name: subname + sub_datas[subj]["-name"],
                  };

                  this.currentColumns.push(col);
                }
              }
              else {
                var jcount = datas[j]["-count"];
                var jsplit = datas[j]["-split"];
                if(jsplit=="," &&  jcount !=undefined && jcount != "") {
                  // handle when no split in count
                  let tmpcount = parseInt(jcount)
                  for(var subcountj=0; subcountj< tmpcount; subcountj++) {
                  col = {
                      id: IND++,
                      name: subname + subcountj,
                  };

                  this.currentColumns.push(col);
                }


                }
                else {

                  col = {
                    id: IND++,
                    name: datas[j]["-name"],
                  };
                  this.currentColumns.push(col);
                }
              }
            }
          }
        }
      }
    },
  },
};
</script>

<style>
.blocs .block {
  color: #e3e3e4;
}
.blocs .block h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-size: 45px;
  line-height: 60px;
  letter-spacing: 10px;
  padding-bottom: 45px;
}
.blocs .block p {
  font-size: 23px;
  line-height: 40px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  letter-spacing: 3px;
}
</style>
