<template>
  <div class="plotxy">
    <div v-if="Verbose">
      <h6>Plot</h6>

      <label>x: {{ x_sel }}</label>
      <label>y: {{ y_sel[0] }}</label>
      <button type="button" class="btn btn-primary" @click="loadData">
        plot
      </button>
    </div>

    <Plotly
      id="plotxy"
      :data="data"
      :layout="layout"
      :display-mode-bar="false"
    ></Plotly>
  </div>
</template>

<script>
function DiffSeconds(t1, t2) {
  var diff = Math.ceil((new Date(t2) - new Date(t1)) / 1000);
  return diff;
}
function mkmon(mon) {
  var res = "01";
  if (mon.match("Feb") != null) {
    res = "02";
  }
  if (mon.match("Mar")) {
    res = "03";
  }
  if (mon.match("Apr")) {
    res = "04";
  }
  if (mon.match("May")) {
    res = "05";
  }
  if (mon.match("Jun")) {
    res = "06";
  }
  if (mon.match("Jul")) {
    res = "07";
  }
  if (mon.match("Aug")) {
    res = "08";
  }
  if (mon.match("Sep")) {
    res = "09";
  }
  if (mon.match("Oct")) {
    res = "10";
  }
  if (mon.match("Nov")) {
    res = "11";
  }
  if (mon.match("Dec")) {
    res = "12";
  }
  return res;
}

import { Plotly } from "vue-plotly";

export default {
  name: "PlotXY",
  components: {
    Plotly,
  },
  props: {
    Verbose: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    rows: {
      type: Array,
    },
    x_sel: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    y_sel: {
      type: Array,
      default: function () {
        return [2];
      },
    },
    y_names: {
      type: Array,
      default: function () {
        return [];
      },
    },
    y_filters: {
      type: Array,
      default: function () {
        return [];
      },
    },
    y_range: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  watch: {
    rows: function () {
      //console.log("Plotxy: watch rows (loadData)")
      this.loadData();
    },
  },
  mounted: function () {
    this.loading = true;
    this.loadData();
    this.loading = false;
  },
  methods: {
    loadData() {
      var rows = this.rows;
      console.log("PlotXY loadData " + rows.length);
      console.log("PlotXY y_sel.length" + this.y_sel.length);
      console.log("PlotXY y_yrange.length" + this.y_range.length);
      console.log("PlotXY y_filters.length" + this.y_filters.length);
      var y_names = this.y_names;
      if (y_names == undefined) y_names = [];

      let y_filter  = this.y_filters[0];
      let y_filter2 = this.y_filters[1];
      let y_filter3 = this.y_filters[2];
      let y_filter4 = this.y_filters[3];
      let y_filter5 = this.y_filters[4];
      let y_filter6 = this.y_filters[5];
      let y_filter7 = this.y_filters[6];
      let y_filter8 = this.y_filters[7];
      let y_filter9 = this.y_filters[8];



      var y_name = "trace 0";
      var y2_name = "trace 1";
      var y3_name = "trace 2";
      var y4_name = "trace 3";
      var y5_name = "trace 4";
      var y6_name = "trace 5";
      var y7_name = "trace 6";
      var y8_name = "trace 7";
      var y9_name = "trace 8";

      var x = [];
      var x2 = [];
      var x3 = [];
      var x4 = [];
      var x5 = [];
      var x6 = [];
      var x7 = [];
      var x8 = [];
      var x9 = [];



      var y = [];
      var y2 = [];
      var y3 = [];
      var y4 = [];
      var y5 = [];
      var y6 = [];
      var y7 = [];
      var y8 = [];
      var y9 = [];

      var xmin_time_str = "";
      var tick0_str = "";
      var xmax_time_str = "";

      for (var i = 0; i < rows.length; i++) {
        var aaa = rows[i].cells[this.x_sel].split(/[:, -]/);
        var day = aaa[0];
        var mon = aaa[1];
        var year = aaa[2];
        var hour = aaa[3];
        var min = aaa[4];
        var sec = aaa[5];
        var str_time =
          year +
          "-" +
          mkmon(mon) +
          "-" +
          day +
          " " +
          hour +
          ":" +
          min +
          ":" +
          sec;
        if(year >= 2014 && xmin_time_str == "") {
          xmin_time_str = str_time;
          tick0_str = year + "-" + mkmon(mon) + "-" + day;
        }
        if(year >=2014 && xmax_time_str == "") {
          xmax_time_str = str_time;
        }
        else if(year > 2014 && xmax_time_str != "") {
          var t0 = Date.parse(xmax_time_str);
          var t = Date.parse(str_time);
          if(t > t0) {
            xmax_time_str = str_time;
          }
        }
        let y_1 = parseFloat(rows[i].cells[this.y_sel[0]]);
        let found = true;
        if(y_filter != undefined && y_filter !="") {
          let ex = new RegExp(y_filter, 'i');
          found = rows[i].data.match(ex);          
          if(this.Verbose)
            console.log(found, rows[i].data)
        }
        if(found) {
          x.push(str_time);
          y.push(y_1);
        }
        if (y_names.length > 0) {
          y_name = y_names[0];
        }

        let found2 = true;
        if (this.y_sel.length > 1) {
          let y_2= parseFloat(rows[i].cells[this.y_sel[1]]);
          if(y_filter2 != undefined && y_filter2 !="") {
            let ex2 = new RegExp(y_filter2, 'i');
            found2 = rows[i].data.match(ex2);
          }
          if(found2) {
            x2.push(str_time);
            y2.push(y_2);
          }
          if (y_names.length > 1) {
            y2_name = y_names[1];
          }         
        }

        let found3 = true;
        if (this.y_sel.length > 2) {
          let y_3= parseFloat(rows[i].cells[this.y_sel[2]]);
          if(y_filter3 != undefined && y_filter3 !="") {
            let ex3 = new RegExp(y_filter3, 'i');
            found3 = rows[i].data.match(ex3);
          }
          if(found3) {
            x3.push(str_time);
            y3.push(y_3);
          }

          if (y_names.length > 2) {
            y3_name = y_names[2];
          }
        }
        if (this.y_sel.length > 3) {
          let y_4= parseFloat(rows[i].cells[this.y_sel[3]]);
          if(y_filter4 == undefined) {
            x4.push(str_time);
            y4.push(y_4);
          }
          else {
            let found4;
            let ex4 = new RegExp(y_filter4, 'i');
            found4 = rows[i].data.match(ex4);
            if(found4) {
              x4.push(str_time);
              y4.push(y_4);
            }

          }

          if (y_names.length > 3) {
            y4_name = y_names[3];
          }
        }
        if (this.y_sel.length > 4) {
          if(y_filter5 == undefined) {
            x5.push(str_time);
            y5.push(parseFloat(rows[i].cells[this.y_sel[4]]));
          }
          else {
            let found5;
            let ex5 = new RegExp(y_filter5, 'i');
            let y_5= parseFloat(rows[i].cells[this.y_sel[4]]);

            found5 = rows[i].data.match(ex5);
            if(found5) {
              x5.push(str_time);
              y5.push(y_5);
            }

          }

          if (y_names.length > 4) {
            y5_name = y_names[4];
          }
        }
        if (this.y_sel.length > 5) {
          let y_6= parseFloat(rows[i].cells[this.y_sel[5]]);
          if(y_filter6 == undefined) {
            x6.push(str_time);
            y6.push(y_6);
          }
          else {
            let found6;
            let ex6 = new RegExp(y_filter6, 'i');
            found6 = rows[i].data.match(ex6);
            if(found6) {
              x6.push(str_time);
              y6.push(y_6);
            }


          }

          if (y_names.length > 5) {
            y6_name = y_names[5];
          }
        }
        if (this.y_sel.length > 6) {
          let y_7= parseFloat(rows[i].cells[this.y_sel[6]]);
          if(y_filter7 == undefined) {
            x7.push(str_time);
            y7.push(y_7);
          }
          else {
            let found7;
            let ex7 = new RegExp(y_filter7, 'i');            
            found7 = rows[i].data.match(ex7);
            if(found7) {
              x7.push(str_time);
              y7.push(y_7);
            }

          }

          if (y_names.length > 6) {
            y7_name = y_names[6];
          }
        }
        if (this.y_sel.length > 7) {
          let y_8= parseFloat(rows[i].cells[this.y_sel[7]]);
          if(y_filter8 == undefined) {
            x8.push(str_time);
            y8.push(y_8);
          }
          else {
            let found8;
            let ex8 = new RegExp(y_filter8, 'i');            
            found8 = rows[i].data.match(ex8);
            if(found8) {
              x8.push(str_time);
              y8.push(y_8);
            }

          }

          if (y_names.length > 7) {
            y8_name = y_names[7];
          }
        }
        if (this.y_sel.length > 8) {
          if(y_filter9 == undefined) {
            x9.push(str_time);
            y9.push(parseFloat(rows[i].cells[this.y_sel[8]]));
          }
          else {
            let found9;
            let ex9 = new RegExp(y_filter9, 'i');
            let y_9= parseFloat(rows[i].cells[this.y_sel[8]]);

            found9 = rows[i].data.match(ex9);
            if(found9) {
              x9.push(str_time);
              y9.push(y_9);
            }

          }

          if (y_names.length > 8) {
            y9_name = y_names[8];
          }
        }
      }
      console.log("timestr: " + xmin_time_str+ " " + xmax_time_str);
      let xrange_str = [xmin_time_str, xmax_time_str];
      if(this.Verbose) {
        console.log("timestr: " + xrange_str);
      }
      let diff_hours = (DiffSeconds(Date.parse(xmin_time_str), Date.parse(xmax_time_str)))/3600;
      let dtick_num = 1000 * 60 * 60 * 24 * 2 ; // 2 day
      if(diff_hours < 24) {
        dtick_num = 1000 * 60 * 60 ;
      }
      else if(diff_hours < 96) {
        dtick_num = 1000 * 60 * 60 * 24; // 1 day
      }
      else {
        dtick_num = 1000 * 60 * 60 * 24 * 2; // 2 day
      }
      if(this.y_range.length == 0) {
        this.layout = {
          xaxis: {
            range: xrange_str,
            autorange: false,
            type: "date",
            //showline: false,
            tick0: tick0_str,
            dtick: dtick_num,
          },                    
        };
      }
      else {
        this.layout = {
          xaxis: {
            range: xrange_str,
            autorange: false,
            type: "date",
            //showline: false,
            tick0: tick0_str,
            dtick: dtick_num,
          },  
          yaxis: {
            range: this.y_range
          }                  
        };


      }


      var data = {
        x: x,
        y: y,
        type: "scatter",
        mode: "lines",
        name: y_name,
      };
      var data2 = {
        x: x2,
        y: y2,
        type: "scatter",
        name: y2_name,
      };

      var data3 = {
        x: x3,
        y: y3,
        type: "scatter",
        name: y3_name,
      };
      var data4 = {
        x: x4,
        y: y4,
        type: "scatter",
        name: y4_name,
      };
      var data5 = {
        x: x5,
        y: y5,
        type: "scatter",
        name: y5_name,
      };
      var data6 = {
        x: x6,
        y: y6,
        type: "scatter",
        name: y6_name,
      };
      var data7 = {
        x: x7,
        y: y7,
        type: "scatter",
        name: y7_name,
      };
      var data8 = {
        x: x8,
        y: y8,
        type: "scatter",
        name: y8_name,
      };
      var data9 = {
        x: x9,
        y: y9,
        type: "scatter",
        name: y9_name,
      };
      if (this.y_sel.length > 8) {
        this.data = [
          data,
          data2,
          data3,
          data4,
          data5,
          data6,
          data7,
          data8,
          data9,
        ];
        return;
      }

      if (this.y_sel.length > 7) {
        this.data = [data, data2, data3, data4, data5, data6, data7, data8];
        return;
      }

      if (this.y_sel.length > 6) {
        this.data = [data, data2, data3, data4, data5, data6, data7];
        return;
      }

      if (this.y_sel.length > 5) {
        this.data = [data, data2, data3, data4, data5, data6];
        return;
      }
      if (this.y_sel.length > 4) {
        this.data = [data, data2, data3, data4, data5];
        return;
      }

      if (this.y_sel.length > 3) {
        this.data = [data, data2, data3, data4];
        return;
      }

      if (this.y_sel.length > 2) {
        this.data = [data, data2, data3];
        return;
      }

      if (this.y_sel.length > 1) {
        this.data = [data, data2];

        
        return;
      }
      this.data = [data];
    },
  },
  data: function () {
    return {
      loading: false,
      data: [
        {
          x: [],
          y: [],
          type: "scatter",
        },
      ],
      layout: {
        xaxis: {
          range: ["26-Jan-2021 19:48:02", "26-Feb-2021 03:00:00"],
          tickmode: "linear", //  If "linear", the placement of the ticks is determined by a starting position `tick0` and a tick step `dtick`
          //tick0: '26-Jan-2021 19:48:02',
          dtick: 1000, // milliseconds
        },
        /* yaxis:
        {
        title: "Test",
        position: 0,
        range: [0,700],
        color: "red",
        showline: true,
        showgrid: true,
        },*/
        title: "UTE (c) 2022",
      },
    };
  },
};
</script>

<style></style>
