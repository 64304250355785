import Vue from 'vue'
import App from "./App.vue"
import router from './router';

import axios from 'axios'
import VueAxios from 'vue-axios'

import BootstrapVue from "bootstrap-vue"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-vue/dist/bootstrap-vue.css"
Vue.use(BootstrapVue)

import firebase from "firebase/compat/app";
import "firebase/compat/auth";

//import Buefy from 'buefy'
//import 'buefy/dist/buefy.css'
//Vue.use(Buefy)

Vue.config.productionTip = false

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAI_gwyLlD_V_HW3MuJbZu3PXxN1cYup8U",
  authDomain: "utenergy-dc0ea.firebaseapp.com",
  projectId: "utenergy-dc0ea",
  storageBucket: "utenergy-dc0ea.appspot.com",
  messagingSenderId: "633332358089",
  appId: "1:633332358089:web:8dec240b9f7f53f19aa618",
  measurementId: "G-488B4S9PEL"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      console.log("auth: getCurrentUser")
      unsubscribe();
      resolve(user);
    }, reject);
  }
)};



Vue.use(VueAxios, axios)


new Vue({      
  router,
  render: h => h(App)
}).$mount('#app')
