<template>
  <div class="group">    
    <b-table  @row-selected="rowSelection" :items="groups" :fields="fields" selectable select-mode="single" primary-key="_id">
    </b-table>    
  </div>
</template>

<script>
function mkmon(mon) {
  var res = "01";
  if (mon.match("Feb") != null) {
    res = "02";
  }
  if (mon.match("Mar")) {
    res = "03";
  }
  if (mon.match("Apr")) {
    res = "04";
  }
  if (mon.match("May")) {
    res = "05";
  }
  if (mon.match("Jun")) {
    res = "06";
  }
  if (mon.match("Jul")) {
    res = "07";
  }
  if (mon.match("Aug")) {
    res = "08";
  }
  if (mon.match("Sep")) {
    res = "09";
  }
  if (mon.match("Oct")) {
    res = "10";
  }
  if (mon.match("Nov")) {
    res = "11";
  }
  if (mon.match("Dec")) {
    res = "12";
  }
  return res;
}

function GROUP(lines) {
  this.lines = lines;
  this.groups = [];
  this.t0 = Date.parse("1970-01-01 00:00:00");

  this.build = function () {
    var lines = this.lines;
    var tprev = 0;
    var lprev = 0;
    var lbegin = 0;
    var tbegin = 0;
    var str = "";
    var str_prev = "";
    var sta_group = 0;
    this.groups = [];
    let ID=0;

    for (var i = 0, NR = i + 1; i < lines.length; i++, NR++) {
      var cells = lines[i].split(",");

      var sheetname = cells[0];
      if (sheetname.match(/Diag/g) != null) {
        continue;
      }
      
      var rtc = cells[1];
      if (rtc == undefined || rtc == "") continue;
      var aaa = rtc.split(/[:, -]/);
      var day = aaa[0];
      var mon = aaa[1];
      var year = aaa[2];
      var hour = aaa[3];
      var min = aaa[4];
      var sec = aaa[5];
      str =
        year +
        "-" +
        mkmon(mon) +
        "-" +
        day +
        " " +
        hour +
        ":" +
        min +
        ":" +
        sec;

      var t = Date.parse(str);

      if (sta_group == 0) {
        sta_group = 1;
        tbegin = t;
        lbegin = NR;
        str_prev = str;
      } else {
        var diff = t - tprev;
        if (diff < 0 || diff > 3600 * 24 * 14) {
          var data = {
            _id: ID++,
            lbegin: lbegin,
            lprev: lprev,
            timeprev: str_prev,
          };
          this.groups.push(data);
          console.log(lbegin + "," + lprev + "," + str_prev + "," + diff);
          tbegin = t;
          lbegin = NR;
          sta_group = 1;
        }
      }
      tprev = t;
      lprev = NR;
      str_prev = str;
    }
    var duration = tprev - tbegin;
    console.log(lbegin + "," + lprev + " " + str_prev + " " + duration);
    var datarest = {
      _id: ID++,
      lbegin: lbegin,
      lprev: lprev,
      timeprev: str_prev,
    };
    this.groups.push(datarest);
  };
}

export default {
  name: "Group",
  props: {
    lines: {
      type: Array,
    },
  },
  watch: {
    lines: function () {
      this.buildGroups();
      console.log(this.g.groups);
    },
  },
  mounted: function () {
    this.loading = true;
    this.buildGroups();
    this.loading = false;
  },
  methods: {
    buildGroups() {
      this.g = new GROUP(this.lines);
      this.g.build();
    },
    rowSelection(item) {
      console.log(item);
      const res = {
        "start": item[0].lbegin,
        "stop": item[0].lprev,
      };
      this.$emit("group-selected", res);
    }
  },
  data: function () {
    return {
      g: null,
      loading: false,
    };
  },
  computed: {
    groups() {
      var groups = [];
      if (this.g != null) {
        groups = this.g.groups;
      }
      return groups;
    },
    fields() {
      var fields = [];
      fields.push({key: "_id", label: "id"} );
      fields.push({key: "lbegin", label: "line begin"});
      fields.push({key: "lprev", label: "line end"});
      fields.push({key: "timeprev", label: "Time Stamp"});
      return fields;
    }
  },
};
</script>

<style></style>
