<template>
  <div class="card">
    <div
      class="alert alert-primary"
      v-if="show_card"
    >
      <h1>{{ cardname }}</h1>
      <table class="table table-bordered table-sm table-striped table-condensed">
        <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <template v-if="item.enable">
              <td style="width: 50%">{{ item.name }}</td>
              <td style="width: 50%">{{ item.value }}</td>
            </template>
          </tr>
        </tbody>
      </table>

      <div v-if="rows.length > 0">
        <p v-if="Verbose">rows: {{ rows.length }} , x_sel: {{ x_sel }}, y_sel: {{ y_sel[0] }}</p>

        <PlotXY
          :x_sel="x_sel"
          :y_sel="y_sel"
          :y_range="y_range"
          :y_names="y_names"
          :y_filters="y_filters"
          :rows="rows"          
          :v-show="rows.length > 0"
        >
        </PlotXY>
      </div>
    </div>
  </div>
</template>

<script>
import PlotXY from "./PlotXY.vue";
export default {
  name: "ACard",
  components: {
    PlotXY,
  },
  props: {
    Verbose: {
      type: Boolean,
      default: function(){
        return false;
      }
    },
    items: {
      type: Array,
    },
    cardname: {
      type: String,
    },
    is_danger: {
      type: Boolean,
    },
    show_card: {
      type: Boolean,
    },
    table: {
      type: Array,
      default: function () {
        return [];
      },
    },
    x_sel: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    y_sel: {
      type: Array,
      default: function () {
        return [2];
      },
    },
    y_range: {
      type: Array,
      default: function () {
        return [];
      },
    },
    y_filters: {
      type: Array,
      default: function () {
        return [];
      },
    },

    y_names: {
      type: Array,
      default: function() {
        return [];
      }
    },
    rows: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  created: function () {
    return {
      msg: "",
    };
  },
  data: function () {
    return {
      msg2: "",
      max_cols: 0,
      data: [
        {
          x: [],
          y: [],          
          type: "scatter",
        },
      ],
      layout: {
        xaxis: {
          tickmode: "linear", //  If "linear", the placement of the ticks is determined by a starting position `tick0` and a tick step `dtick`
          tick0: "25-Dec-2021 14:48:02",
          dtick: 1000, // milliseconds
        },
       
        title: "xxx",
      },
    };
  },
  mounted: function () {
    this.loading = true;
    this.loadData();
    this.loading = false;
  },
  computed: {
    comp_rows() {
      var rows = [];
      this.table.forEach((row) => rows.push(row));
      console.log("ACard: table.length " + this.table.length);
      return rows;
    },
    cols() {
      return this.max_cols;
    },
  },
  watch: {
    show_card: function () {
      if(this.Verbose)
        console.log("card: show card");      
      this.update_msg();
      this.$forceUpdate();
    },
    table: function () {
      if(this.Verbose)
        console.log("card: watch table");
      this.$forceUpdate();
    },
    rows: function (newVal) {
      if(this.Verbose)
      console.log("card: watch rows change to " + newVal.length);
      var len = 0;
      var max_len = len;
      for (var i = 0; i < newVal.length; i++) {
        len = newVal[i].cells.length;
        if (max_len < len) {
          max_len = len;
        }
      }
      this.max_cols = max_len;
      this.$forceUpdate();
    },
    x_sel: function (newVal) {
      console.log("card: watch x_sel change to" + newVal);
      this.$forceUpdate();
    },
    y_range: function(newVal) {
      console.log("card: watch y_range change to" + newVal);
      this.$forceUpdate();
    }
  },
  methods: {
    update_msg: function () {
      console.log("card: " + this.cardname);
      if(this.Verbose)
        console.log("card.y_range: " + this.y_range);
    },
    loadData() {
      var rows = this.rows;
      var x = [];
      var y = [];
      var y2 = [];
      for (var i = 0; i < rows.length; i++) {
        x.push(rows[i].cells[this.x_sel]);
        y.push(parseFloat(rows[i].cells[this.y_sel[0]]));
        if(this.y_sel.length > 1) {
          y2.push(parseFloat(rows[i].cells[this.y_sel[0]]));
        }
      }
      var data = [
        {
          x: x,
          y: y,
          y2: y2,
          type: "scatter",
        },
      ];
      this.data = data;      
    },
  },
};
</script>

<style scoped>
.alert {
  margin-bottom: 0;
}

</style>
