<template>
  <div class="container">
    <form @submit.prevent="login" class="mb-3">
      <h2>Login</h2>
      <div class="mb-3">
        <input
          type="email"
          class="form-control"
          placeholder="Email address..."
          v-model="email"
        />
        <div id="emailHelp" class="form-text">
          We'll never share your email with anyone else.
        </div>
      </div>
      <div class="mb-3">
        <input
          type="password"
          class="form-control"
          placeholder="password..."
          v-model="password"
        />
      </div>
      <button type="submit" class="btn btn-primary me-2">Login</button>     
    </form>
    <router-link to="/ForgotPassword">I've forgot the password.</router-link>    
  </div>
</template>

<script>
import firebase from "firebase/compat/app";

export default {
  
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      error: null,
    };
  },
  methods: {
    login() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          alert("Successfully logged in");
          this.$router.push("/tracker");
        })
        .catch((error) => {
          alert(error.message);
        });
    },
    
  },
};
</script>
